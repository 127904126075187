import React, { useState } from "react";
import styled from "styled-components";
import Day from './Day';
import LeftArrow from '../../assets/icons/leftArrow.png';
import RightArrow from '../../assets/icons/rightArrow.png'

export const monthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "Octuber",
    "November",
    "December"
]

export const dayArr = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export const day31MonthArr = [
    "January",
    "March",
    "May",
    "July",
    "August",
    "October",
    "December"
]


export const getDate = (d: Date) => {
    let indexMonth = d.getMonth();
    let month = monthArr[indexMonth];
    let indexDay = d.getDay();
    let day = dayArr[indexDay];
    let date = d.getDate();
    let year = d.getFullYear();

    let diferenceDays = (date % 7)
    let startingDay = (indexDay - diferenceDays + 1) % 7;
    if (diferenceDays > indexDay) startingDay = (indexDay + 8 - diferenceDays) % 7;
    let startOfMonth = dayArr[startingDay]

    let totalDays = 30;
    if (month == "February" && year % 4 !== 0) totalDays = 28;
    else if (month == "February" && year % 4 == 0) totalDays = 29;
    else if (day31MonthArr.includes(month)) totalDays = 31;

    return { indexMonth, month, indexDay, day, date, year, startingDay, startOfMonth, totalDays }
}

interface WidgetType{
    inputDate: string,
    position: string,
    changeMonth: Function
}
export default function Calendar(props:WidgetType) {
    //const d = new Date(props.inputDate);
    const [fDay, fMonth, fYear] = props.inputDate.split("/");
    const fixedDate = `${fMonth}/${fDay}/${fYear}`
    const d = new Date(fixedDate);
    const todayDate = new Date();
    const dateInfo = getDate(d)
    const todayDateInfo = getDate(todayDate)
    const globalDate = `${todayDateInfo.date}/${todayDateInfo.indexMonth + 1}/${todayDateInfo.year}`

    const clickedLeft = () => { props.changeMonth('previous') }
    const clickedRight = () => { props.changeMonth('next') }

    let daysElements = [];
    let dayElement;
    for(let i = 0; i < dateInfo.totalDays + dateInfo.startingDay; i ++){
        if(i < dateInfo.startingDay) dayElement = <div key={i}>{" "}</div>
        else {
            let day = i- dateInfo.startingDay +1;
            let month = dateInfo.indexMonth + 1;
            let year = dateInfo.year;
            dayElement = <Day date={`${day}/${month}/${year}`} globalDate={globalDate} key={i} >{day}</Day>;
        }
        daysElements.push(dayElement)
    }

    return (
        <CalendarMain>
            <CalendarBox>
                
                <MontTitle>
                    <PrevButton onClick={clickedLeft} position={props.position} src={LeftArrow}/>
                    <h2>{dateInfo.month}</h2>
                    <NextButton onClick={clickedRight} position={props.position} src={RightArrow}/>
                </MontTitle>

                <WeekDays>
                    <div>Su</div>
                    <div>Mo</div>
                    <div>Tu</div>
                    <div>We</div>
                    <div>Th</div>
                    <div>Fr</div>
                    <div>Sa</div>
                </WeekDays>
                <NumberDays>
                    {daysElements}
                </NumberDays>
                
            </CalendarBox>
            
        </CalendarMain>
    );
}

const CalendarMain = styled.div`
    //width: 90%;
    overflow-x: hidden;
`

const CalendarBox = styled.div`
    font-family: 'trade-gothic';
`

const MontTitle = styled.div`
    text-transform: uppercase;
    position: relative;
    & h2{
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        color: #777777;
        margin: 15px;
    }    
`

const PrevButton = styled.img<{position:string}>`
    display: ${(props)=> props.position.includes("first")? "block": "none"};
    position: absolute;
    top: 6px;
    left: 10px;
    height: 20px;
    &:hover{
        cursor: pointer;
    }
`
const NextButton = styled.img<{position:string}>`
    display: ${(props)=> props.position.includes("last")? "block": "none"};
    position: absolute;
    top: 6px;
    right: 10px;
    height: 20px;
    &:hover{
        cursor: pointer;
    }
`

const WeekDays = styled.div`
    overflow-x: hidden;
    //padding: 5px 0px 5px 5px;
    display: grid;
    grid-template-columns: repeat(7, 38px);
    grid-gap: 0px;
    & div{
        font-family: 'gotham';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #777777;
    }

`

const NumberDays = styled.div`
    font-family: 'gotham';
    font-size: 12px;
    //padding: 5px 0px 5px 5px;
    //padding: 5px 15px;
    display: grid;
    grid-template-columns: repeat(7, 38px);
    grid-gap: 0px;
`