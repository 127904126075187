import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEventInfo } from "../../services/events/eventsReq";
import styled from 'styled-components';

import EventImageComponent from '../eventFormElements/eventImage'
import EventNameComponent from '../eventFormElements/eventName'
import ButtonsArea from '../eventFormElements/buttonsArea'

import ModalNotification from "../modals/modalNotification";
import EventDescription from "../eventFormElements/eventDescription";
import SwitchButton from "../switchButton/switchButton"
import DateAndType from "../eventFormElements/dateAndType";


interface widgetProps {
    type: string
    handleSave: Function,
    handleDelete?: Function
};

export interface TEventData {
    id?: string,
    EventName: string,
    EventImage: string,
    Description: string,
    Date: any,
    Status: boolean,
    Type: string
}


const emptyEvent: TEventData = {
    EventImage: "",
    EventName: "",
    Description: "",
    Date: "",
    Status: false,
    Type: ""
}

interface TEventImageData {
    url: string,
    file: any
}
export interface TImageFiles {
    EventImageData: TEventImageData
}

const emptyImageFiles = {
    EventImageData: {
        url: "",
        file: undefined
    }
}

const requiredFields: Array<string> = ["EventName", "Description", "Date"]
const errorMesages: Object = {
    "EventName": "Write a Name for this Event",
    "Description": "Add a Description for the Event",
    "Date": "Write a date for the Event",
    "Status": "Set the current status for the Event",
}

const checkRequired = (event: TEventData) => {
    let activateAlert = { state: false, element: "" };
    let newEvent: any = { ...event }
    for (let key in event) {
        if (requiredFields.includes(key)) {
            if (!newEvent[key] || !newEvent[key].length) {
                activateAlert.state = true;
                activateAlert.element = key;
                break;
            }
        }
    }
    return activateAlert;
}

const emptyOptions = {
    "type": [{
        name: "especiale",
        active: true
    },{
        name: "standard",
        active: true
    },{
        name: "non token holder",
        active: true
    }]
}
const emptyModal = {
    acceptText: "",
    cancelText: "",
    closeModal: () => {},
    confirmModal: () => {},
    message: ""
}


function searchClassRecursiveUp(target:any, tries:number):boolean{
    if(!(tries > 0) || !target.parentElement) return false;
    if(target.className.includes("event-calendar-modal")) return true;
    else return searchClassRecursiveUp( target.parentElement, tries-1);
}

export default function EventForm(props: widgetProps) {

    let [imageFiles, setImageFiles] = useState<TImageFiles>(emptyImageFiles)
    let [eventData, setEventData] = useState(emptyEvent);
    let [alert, setAlert] = useState({ state: false, element: "" });
    let [dropMenuOption, setDropMenuOptions] = useState<{ type: any }>(emptyOptions);
    let [modalInfo, setModalInfo] = useState(emptyModal);
    let [confirmationModalState, setConfirmationModalState] = useState(false);
    let navigate = useNavigate();


    let [typeFlag, setTypeFlag] = useState(false)
    let updateTypeFlag = () => setTypeFlag(prevState => !prevState)
    let [modalCalendarFlag, setModalCalendarFlag] = useState(false);
    let updateModalCalendarFlag = () => setModalCalendarFlag(prevState => !prevState)

    let { id } = useParams()

    useEffect(() => {
        setImageFiles(emptyImageFiles)
        setAlert({ state: false, element: "" })

        if ((props.type === "edit") && id) {
            getEventInfo(id).then((response: TEventData) => {
                //localGetEventInfo(id).then((response: TEventData) => {
                let initialImagesData = {
                    EventImageData: {
                        url: response.EventImage,
                        file: undefined
                    }
                }
                //response.Validated = true;
                setEventData(response);
                setImageFiles(initialImagesData);
                
            }).catch((error) => { console.log("error: " + error) })
        }

        //=== drop menu options

    }, []);



    const updateEventAttributes = (property: string, value: string | Array<string> | boolean) => {
        setEventData((prevState: TEventData) => {
            let newState: TEventData | any = { ...prevState };
            newState[property] = value;
            return newState;
        })
    }


    // Reset dropmenu state when clicking outside
    const resetDropStates = (event: any) => {
            if (typeFlag) setTypeFlag(false);

            let clickedModalEventCalendar = searchClassRecursiveUp(event.target, 6)
            if (!clickedModalEventCalendar && modalCalendarFlag) {
                setModalCalendarFlag(false);
            }
    }


    const confirmSaving = () => {
        setConfirmationModalState(false)
        setAlert({ state: false, element: "" })
       
        let temporalDate = new Date(eventData.Date.replace(/-/g, "/"));
        let timeMillisDate = temporalDate.getTime();
        let updatedEventData = { 
            ...eventData,
            Date: timeMillisDate
        };
        let saveParameters = {
            idEvent: id,
            eventData: updatedEventData,
            eventImageFiles: imageFiles,
        }
        props.handleSave(saveParameters)
    }

    const confirmDelete = () => {
        if (props.handleDelete){
        setConfirmationModalState(false)
        setAlert({ state: false, element: "" })
        props.handleDelete(id)
        }
    }


    //===== Buttons
    const handleSave = () => {
        const modalConfirmationInfo = {
            acceptText: "Save",
            cancelText: "Cancel",
            closeModal: () => { setConfirmationModalState(false) },
            confirmModal: confirmSaving,
            message: "Are you sure to save this event?"
        }
        setModalInfo(modalConfirmationInfo)
        let activateAlert = checkRequired(eventData);
        setAlert(activateAlert);
        if (!activateAlert.state) {
            setConfirmationModalState(true);
        }
    }

    const handleDelete = () => {
        if (props.handleDelete){
        const modalDeleteInfo = {
            acceptText: "Delete",
            cancelText: "Cancel",
            closeModal: () => { setConfirmationModalState(false) },
            confirmModal: confirmDelete,
            message: "Are you sure to delete this event?"
        }
        setModalInfo(modalDeleteInfo);
        setConfirmationModalState(true);
    }
    }

    const handleCancel = () => { navigate("/events") };
   

    const changeDraftState = () => {
        let activateAlert = checkRequired(eventData);
        setAlert(activateAlert);
        if (!activateAlert.state) {
            updateEventAttributes("Status", !eventData.Status)
        }
    }

    return (
        <FormEvent onClick={resetDropStates}>

            { confirmationModalState && 
                <ModalNotification {...modalInfo} />
            }

            <Title><h2>{props.type === "edit" ? "EDIT" : 'CREATE'} EVENT</h2></Title>
            <RequiredAdd> <p><PurpleDot /> Required fields </p></RequiredAdd>
            <EventImageComponent eventData={eventData} updateEventAttributes={updateEventAttributes} updateImageFiles={(data: TImageFiles) => setImageFiles(data)} imageFiles={imageFiles} />
            <EventDescription eventData={eventData} updateEventAttributes={updateEventAttributes} />
            <EventNameComponent eventData={eventData} updateEventAttributes={updateEventAttributes} />
            <DateAndType eventData={eventData} updateEventAttributes={updateEventAttributes} typeFlag={typeFlag} updateTypeFlag={updateTypeFlag} dropOptions={dropMenuOption || []} modalCalendarFlag={modalCalendarFlag} updateModalCalendarFlag={updateModalCalendarFlag}/>
            {alert.state && <ErrorMessage>*{`${errorMesages[alert.element as keyof Object]}`}</ErrorMessage>}

            {props.type !== "edit" ? '' : (
                <Draft><DraftText state={eventData.Status}>{eventData.Status ? "Launched" : "Draft"}</DraftText><SwitchButton state={eventData.Status} size="25" changeState={changeDraftState} /></Draft>
            )}

            <ButtonsArea eventData={eventData} type={props.type} handleSave={handleSave} handleDelete={handleDelete} handleCancel={handleCancel} />
        </FormEvent>
    );
}



const FormEvent = styled.div`
    box-sizing: border-box;
    padding: 40px 20px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    max-width: 900px;
`

const Title = styled.div`
    & h2{
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #830F5B;
        margin: 0;
    }
`

const RequiredAdd = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    & p{
        margin: 0;
    }
`

const PurpleDot = styled.span`
    &::before{
        color: #830F5B;
        content: "*";
    }
`

const Draft = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
`
const DraftText = styled.p<{ state: boolean }>`
    color: ${({ state }) => state ? "#84004C" : "#9B9B9B"};
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 26.5497px;
    line-height: 32px;
    margin: 0px;
`

const ErrorMessage = styled.p`
    margin: 0;
    color: #c71111;
    flex: 1;
    text-align: left;
`
const SuccessMessage = styled.p`
    margin: 0;
    color: #12c71b;
    flex: 1;
    text-align: left;
`