import { createAsyncThunk, createImmutableStateInvariantMiddleware, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import Cookies from 'universal-cookie';

export interface imageSpecs{
    title: string,
    description: string,
    quantity: number,
    additionalDescription: string
    fullfilled: boolean,
}

export interface imageInfo{
    id: string,
    url: string,
    file?: any
    info?: imageSpecs,
    state: string //can be "Original" "ToAdd" "ToEdit" "ToDelete"
}

export interface imageData {
    bannerImage: any,
    logoImage: any,
    additionalImages: Array<imageInfo>|any,
    tokenImages: Array<imageInfo>|any
}

export interface ImagesState {
    status: string,
    data: imageData
}

const initialState: ImagesState = {
    status: 'initial',
    data: {
        bannerImage:undefined,
        logoImage:undefined,
        additionalImages: [],
        tokenImages: []
    }
};


export const imageCollectionSlice = createSlice({
    name: 'imagesCollection',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setImagesInfo: (state, action) => {
            state.data = action.payload;
            //console.log("saving images info")
            //console.log(action.payload)
        }
    },
    
});

export const { setImagesInfo } = imageCollectionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectImagesInfo = (state: RootState) => state.imagesCollection;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default imageCollectionSlice.reducer;
