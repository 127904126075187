import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import { app } from '../../config/config'
let BACKEND_URL = app.BACKEND_URL;


//let BACKEND_URL = 'https://vinereserveclub.smartssi.net';
/*if (process.env.BACKEND_URL) BACKEND_URL = process.env.BACKEND_URL*/


interface TGetCollection {
    collectionId: string
}
interface TCreateCollection {
    data: any
}
interface TUpdateCollection {
    collectionId: string,
    data: any
}


export interface TResponseGet {
    data: any
}
export interface TResponseCreate {
        collection_id: string,
        result: string
}
export interface TResponseUpdate {
    data: any
}
interface TResponseGetLocal {
    BannerImage: string,
    LogoImage: string,
    AditionalImages: Array<string>,
    TokenImages: Array<string>,
    Description: string,
    CollectionName: string,
    Currencies: Array<string>,
    Category: string,
    RoyaltyWallet: string,
    Price: string,
    CreatorWallet: string,
    RoyaltyPercentage: string,
    Launched: boolean
}

export function getCollection({ collectionId }: TGetCollection) {
    const axiosOptions = {
        method: 'GET',
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections/${collectionId}`
    };

    return new Promise<AxiosResponse<TResponseGet>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                reject('No possible to get collection info')
            })
    })
}





export function getLocalCollection({ collectionId }: TGetCollection) {
    let localCollection: TResponseGetLocal = {
        BannerImage: "https://cdn.pixabay.com/photo/2022/08/15/03/07/path-7387018_960_720.jpg",
        LogoImage: "https://media.istockphoto.com/id/1047482606/es/foto/atractivo-joven-hombre-con-perfume.jpg?s=612x612&w=0&k=20&c=vXALj6WyS9WQWKsm08055Xj_7KMmnTRs0vslRZGoqag=",
        AditionalImages: [
            "https://cdn.pixabay.com/photo/2018/12/28/01/34/rum-3898745__340.jpg"
        ],
        TokenImages: [
            "https://cdn.pixabay.com/photo/2017/03/14/11/41/perfume-2142824__340.jpg",
            "https://cdn.pixabay.com/photo/2018/01/10/13/47/essential-oil-3073901__340.jpg",
            "https://cdn.pixabay.com/photo/2017/10/03/12/07/bottle-2812214__340.jpg",
            "https://cdn.pixabay.com/photo/2014/08/17/20/24/perfume-420174__340.jpg",
            "https://cdn.pixabay.com/photo/2016/06/03/16/03/essential-oils-1433693__340.jpg"
        ],
        Description: `Rituals combines generative music and generative artwork to create 1000 unique windows into a singular parametric universe. The music and art are crafted to infinitely evolve, giving collectors a subtly new experience upon each viewing. If left running, a single Ritual will continue generating music and art without repeating for ~9 million years. We invite you to hold space for yourself and enjoy a moment of mindful stillness. The first 200 were minted in real life at Bright Moments in Venice, CA, USA in September 2021.`,
        CollectionName: "Collection Name Example",
        Currencies: ["Credit", "Debit"],
        Category: "Wine",
        RoyaltyWallet: "Royalty Wallet Address Example",
        Price: "2",
        CreatorWallet: "VRC - 6522",
        RoyaltyPercentage: "10",
        Launched: false
    }
    return localCollection
}




export function createCollection({ data }: TCreateCollection) {

    const axiosOptions = {
        method: 'POST',
        data: data,
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections`
    };
    return new Promise<AxiosResponse<TResponseCreate>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject(error)
            })
    })
}






interface TUpdateCollection {
    data: any
}
export function updateCollection({ data, collectionId }: TUpdateCollection) {
    const axiosOptions = {
        method: 'PUT',
        data: data,
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections/${collectionId}`
    };

    return new Promise<AxiosResponse<TResponseUpdate>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject('No possible to update collection')
            })
    })
}





interface TDeleteCollection {
    data: any
}
export function deleteCollection({ data }: TDeleteCollection) {
    
    const axiosOptions = {
        method: 'DELETE',
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections/${data}`
    };

    return new Promise<AxiosResponse<TResponseUpdate>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject('No possible to delete collection')
            })
    })
}






interface TgetPresignedUrlProps { collection_id: string, fileType: string, image_name: string }
export function getPresignedUrl(props: TgetPresignedUrlProps) {
  const  { collection_id, fileType, image_name } = props;

  const payload = {
    type: "uploadImage",
    collection_id, fileType, image_name,
  };

  return new Promise<any>((resolve, reject) => {
    fetch(`${BACKEND_URL}/api/inspector`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve(data)
    })
    .catch((error) => console.log('userInfo error', error));
  })
}




export interface TResponseConfig{
    category: Array<any>,
    currencies: Array<any>,
    creator_wallet?: Array<any>
}
export function getDropmenuOptions(){    
    const axiosOptions = {
        method: 'GET',
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections/config`
    };

    return new Promise<AxiosResponse<TResponseConfig>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject('No possible to update collection')
            })
    })
}