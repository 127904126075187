import React from 'react'
import styled from 'styled-components'
import { applicationDataType } from '../../../services/apiSlice/apiTypes'

interface propsType { applyData: applicationDataType, closeModal: () => void, errorMessage: string }
export default function ErrorRequestModal(props: propsType) {
  const { applyData, errorMessage } = props;
  
  return(
    <Container>
      <Title> Opps! </Title>
      { errorMessage &&
        <Message>
          { errorMessage }
        </Message>
      }

      <Button
        onClick={() => props.closeModal()}
      >
        OK
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 500px;
  height: 300px;
  background-color: #F0EEDD;
  border-radius: 10px;
`

const Title = styled.div`
  font-family: 'trade-gothic';
  font-size: 30px;
  font-weight: 600;
  color: #84004C;
`
const Message = styled.div`
  font-family: 'trade-gothic';
  font-size: 23px;
  padding: 0px 25px;
  color: #676767;
`

const Button = styled.button`
  font-family: 'trade-gothic';
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  width: 200px;
  height: 50px;
  border: 1px solid #84004C;
  border-radius: 5px;
  background-color: #84004C;
  cursor: pointer;
  &:hover {
    background-color: #84004ccf;
    color: #fff;
  }
`