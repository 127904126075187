import React, { useState } from "react";
import "./Collections.css";
import CollectionList from '../../components/collection/collectionList';
import PlusImage from '../../assets/icons/plus.png';
import { NavLink } from "react-router-dom";

//interface widgetProps { children: string }
export default function Collections() {
    return (
        <div className="collections">
            <div className="collections-main">
                <div className="collections-main__NewCollection">
                    <NavLink to="/collections/new"  className="newCollection-button">
                        <h3 className="newCollection-buttonTitle">Add New Collection </h3>
                        <img src={PlusImage} alt="plusButton" className="newCollection-btnImg" />
                    </NavLink>
                </div>

                <div className="collections-main__ListCollection">
                    <CollectionList />
                </div>
            </div>
        </div>
    );
}