import React, {useState} from "react";
import { SectionForm, SectionTitle, PurpleDot, TextInputArea } from './eventStylingForm'
import {TEventData} from '../eventsList/eventForm'

interface widgetProps { eventData: TEventData, updateEventAttributes: Function }
export default function EventName( props: widgetProps ) {
    let { eventData, updateEventAttributes } = props;
    let changeEventName = (event: any) => { updateEventAttributes("EventName", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Event Name <PurpleDot /></SectionTitle>
                <TextInputArea type="text" value={eventData.EventName} onChange={changeEventName} withLogo={false} disabled={eventData.Status} />
            </SectionForm>
    );
}