import React, { useState } from "react";
import "./EditCollection.css";
import { useParams, useNavigate } from "react-router-dom";
import CollectionForm from '../../components/collection/collectionForm';
import { deleteCollection, updateCollection, createCollection, getDropmenuOptions } from "../../services/collections/collectionReq";
import { TResponseGet, TResponseCreate, TResponseConfig } from '../../services/collections/collectionReq'
import { uploadFoto, deleteImage, updateToken } from '../../services/collections/imageToFile';

interface TfunctionParams {
    idCollection?: any,
    collectionData: any,
    bannerImage: any,
    logoImage: any,
    additionalImages: any,
    tokenImages: any
}

export default function EditCollection() {
    let navigate = useNavigate();

    const handleSave = (parameters: TfunctionParams) => {
        let { idCollection, collectionData, bannerImage, logoImage, additionalImages, tokenImages } = parameters

        let payloadCreate = {
            "collection_name": collectionData.CollectionName,
            "description": collectionData.Description,
            "category": collectionData.Category,
            "creator_wallet": collectionData.CreatorWallet,
            "currencies": collectionData.Currencies.join(","),
            "price": collectionData.Price,
            "royalty_percentage": collectionData.RoyaltyPercentage,
            "royalty_wallet_address": collectionData.RoyaltyWallet
        }

        
        //==== Adding new images
        if (bannerImage) {
            let bannerType = bannerImage.file ? bannerImage.file.type.split("/").pop() : bannerImage.type.split("/").pop()
            let bannerFile = bannerImage
            uploadFoto(idCollection, bannerType, "banner_image", bannerFile);
        }
        if (logoImage) {
            let tokenType = logoImage.file ? logoImage.file.type.split("/").pop() : logoImage.type.split("/").pop()
            let tokenFile = logoImage.file ? logoImage.file : logoImage
            uploadFoto(idCollection, tokenType, "logo_image", tokenFile);
        }

        let filteredAdditionalFiles = additionalImages.filter((element: any, index: number) => element.state === "ToAdd");
        let formatedPayloadAdditionalList = []
        for (let i = 0; i < filteredAdditionalFiles.length; i++) {
            uploadFoto(idCollection, filteredAdditionalFiles[i].file.type.split("/").pop(), "additional_image", filteredAdditionalFiles[i].file);
            formatedPayloadAdditionalList.push({ "uploadFotoParameters": [idCollection, filteredAdditionalFiles[i].file.type.split("/").pop(), "additional_image", filteredAdditionalFiles[i].file] })
        }
        if (filteredAdditionalFiles.length) {
            console.log("=== Added additional images with files ===");
            console.log(formatedPayloadAdditionalList)
        }



        //==== Adding new images with info
        let filteredTokenFiles = tokenImages.filter((element: any, index: number) => element.state === "ToAdd");
        let formatedPayloadTokenList = []
        for (let i = 0; i < filteredTokenFiles.length; i++) {
            let formatedPayload = {
                title: filteredTokenFiles[i].info.title,
                desc: filteredTokenFiles[i].info.description,
                amount: filteredTokenFiles[i].info.quantity,
                "additional_desc": filteredTokenFiles[i].info.additionalDescription
            }
            uploadFoto(idCollection, filteredTokenFiles[i].file.type.split("/").pop(), "token_image", filteredTokenFiles[i].file, formatedPayload);

            let controlCall: any = { ...formatedPayload }
            controlCall["uploadFotoParameters"] = [idCollection, filteredTokenFiles[i].file.type.split("/").pop(), "token_image", filteredTokenFiles[i].file, formatedPayload]
            formatedPayloadTokenList.push(controlCall);
        }
        if (filteredTokenFiles.length) {
            console.log("=== Added token images with files ===");
            console.log(formatedPayloadTokenList)
        }



        //==== Deleting images
        let filteredDeleteAdditional = additionalImages.filter((element: any, index: number) => element.state === "ToDelete");
        let formatedPayloadAdditionalListDelete = []
        for (let j = 0; j < filteredDeleteAdditional.length; j++) {
            deleteImage(idCollection, filteredDeleteAdditional[j].url.split("/").pop());
            
            formatedPayloadAdditionalListDelete.push({"deleteImage": [idCollection, filteredDeleteAdditional[j].url.split("/").pop()]})
        }
        if (filteredDeleteAdditional.length) {
            console.log("=== Deleted additional images ===");
            console.log(formatedPayloadAdditionalListDelete)
        }


        let filteredDeleteToken = tokenImages.filter((element: any, index: number) => element.state === "ToDelete");
        let formatedPayloadTokenListDelete = []
        for (let i = 0; i < filteredDeleteToken.length; i++) {
            let tokenIdTest = filteredDeleteToken[i].url.split("/").pop();
            let tokenId = tokenIdTest.includes(".png") ? tokenIdTest : filteredDeleteToken[i].id;
            deleteImage(idCollection, tokenId);

            formatedPayloadTokenListDelete.push({"deleteImageParams": [idCollection, tokenId]})
        }
        if (filteredDeleteAdditional.length) {
            console.log("=== Deleted token images ===");
            console.log(formatedPayloadAdditionalListDelete)
        }

        //=== Updating tokens
        let filteredUpdateToken = tokenImages.filter((element: any, index: number) => element.state === "ToEdit");
        let formatedPayloadTokenListUpdate = []
        for (let i = 0; i < filteredUpdateToken.length; i++) {
            let formatedPayload = {
                title: filteredUpdateToken[i].info.title,
                desc: filteredUpdateToken[i].info.description,
                amount: filteredUpdateToken[i].info.quantity,
                "additional_desc": filteredUpdateToken[i].info.additionalDescription
            }
            updateToken(idCollection, filteredUpdateToken[i].id, formatedPayload);

            let controlCallUpdate: any = { ...formatedPayload }
            controlCallUpdate["updateTokenParams"] = [idCollection, filteredUpdateToken[i].id, formatedPayload]
            formatedPayloadTokenListUpdate.push(controlCallUpdate);
        }
        if (filteredUpdateToken.length) {
            console.log("=== Updated token images ===");
            console.log(formatedPayloadTokenListUpdate)
        }
        


        updateCollection({
            collectionId: String(idCollection),
            data: payloadCreate
        }).then((response) => {
            navigate("/collections")
        })
    }

    const handleDelete = (id: string) => {
        deleteCollection({ data: id }).then((response) => {
            console.log(response);
            navigate("/collections")
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className="editCollection">
            <CollectionForm type="edit" handleSave={handleSave} handleDelete={handleDelete} />
        </div>
    );
}