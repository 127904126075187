import React, {useState} from "react";
import { SectionForm, SectionTitle, PurpleDot, TextInputArea } from './walletStylingForm'
import {TWalletData} from '../walletsList/walletForm'

interface widgetProps { walletData: TWalletData, updateWalletAttributes: Function }
export default function WalletName( props: widgetProps ) {
    let { walletData, updateWalletAttributes } = props;
    let changeCollectionName = (event: any) => { updateWalletAttributes("WalletName", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Wallet Name <PurpleDot /></SectionTitle>
                <TextInputArea type="text" value={walletData.WalletName} onChange={changeCollectionName} withLogo={false} disabled={walletData.Validated} />
            </SectionForm>
    );
}