import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { app } from '../../config/config';

interface Tdate { date: string; }
interface Tboungalow {
    day: number,
    bungalowsOccuped: number,
    totalBungalows: number,
    message: string
}
interface TdateApiResponse {
    bungalowType: string,
    avilability: Array<Tboungalow>
}
export function getOcupedDates(props: Tdate) {
    if (props.date.match(/\d+\/\d+\/\d+/)) {
        const [day, month, year] = props.date.split("/")
        const axiosOptions = {
            method: 'GET',
            withCredentials: true,
            url: `${app.BACKEND_URL}/api/booking/${year}-${month}`
        };

        return new Promise<AxiosResponse<TdateApiResponse>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    // handle success
                    resolve(response);
                })
                .catch(function (error) {
                    // handle error
                    //console.log(error)
                    reject('No possible to authenticate')
                })
        })
    }
    else{
        console.log("No valid date entered");
        return null;
    }
}



export interface TbookingPost {
    "reservation_start": string,
    "reservation_end": string,
    "type": string,
    "guests": number,
    "bungalows": number
}
export function bookingPost(data:TbookingPost){
    const axiosOptions = {
        method: 'POST',
        withCredentials: true,
        url: `${app.BACKEND_URL}/api/booking`,
        data: data
    }

    return new Promise<AxiosResponse<TdateApiResponse>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject('No possible to authenticate')
            })
    })
}

interface TdateLocalResponse {
    [id:string]: TdateApiResponse
}
export function getLocalDates(props: Tdate){
    if (props.date.match(/\d+\/\d+\/\d+/)) {
        const [day, month, year] = props.date.split("/");
        const usedDates: TdateLocalResponse = {
            "10":{
                "bungalowType": "standard",
                "avilability": []
            },
            "11":{
                "bungalowType": "standard",
                "avilability": [
                    {
                        "day": 1,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 2,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 3,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 4,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 5,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 6,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 7,
                        "bungalowsOccuped": 2,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 15,
                        "bungalowsOccuped": 1,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 16,
                        "bungalowsOccuped": 1,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 17,
                        "bungalowsOccuped": 1,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 18,
                        "bungalowsOccuped": 1,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 23,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    },
                    {
                        "day": 24,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    },
                    {
                        "day": 25,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    },
                    {
                        "day": 26,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    },
                    {
                        "day": 27,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    },
                    {
                        "day": 28,
                        "bungalowsOccuped": 9,
                        "totalBungalows": 9,
                        "message": "No vacancy"
                    }
                ]
            },
            "12":{
                "bungalowType": "standard",
                "avilability": [
                    {
                        "day": 7,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 8,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 9,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 10,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 11,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 12,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 13,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    },
                    {
                        "day": 14,
                        "bungalowsOccuped": 3,
                        "totalBungalows": 9,
                        "message": "Several bungalows available"
                    }
                ]
            }
        }
        return usedDates[month];
    }
    else{
        console.log("No date founded");
        return null
    }
}