import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { popUpState, popUpPayloadAction} from "./types"

const initialState: popUpState = {
  type: undefined,
  message: undefined,
  timestamp: undefined
}

const popUpSlice = createSlice({
  name: 'popUpSlice',
  initialState,
  reducers: {
    setLogEvent(state, action: PayloadAction<popUpPayloadAction>) {
      const { payload } = action

      state.type = payload.type
      state.message = payload.message
      state.timestamp = Date.now()
    }
  }
})

export const {
  setLogEvent
} = popUpSlice.actions

export default popUpSlice.reducer