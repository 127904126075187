import React, { useState } from "react";
import styled from "styled-components";
import DownArrowPic from '../../assets/icons/downArrow.png'

interface widgetProps {
    selected: string,
    type: string,
    options: Array<any>,
    updateSelected: Function,
    dropFlag: boolean,
    updateDropFlag: Function
}
export default function CustomDropMenu3(props: widgetProps) {
    const { selected, options, type, updateSelected, dropFlag, updateDropFlag } = props;
    const [dropState, setDropState] = useState(false);

    const changeDropState = () => {
        updateDropFlag(!dropFlag)
    }
    const updateState = (optionSelected: string) => {
        updateSelected(optionSelected)
    }

    return (
        <CustomDropPemu2App>
            <MainInputBox onClick={changeDropState} className="Custom" >
                <SingleText>YEAR VIEW</SingleText>
            </MainInputBox>

            <SelectionBox dropState={props.dropFlag}>
                {options.map((element, index) => {
                    return (
                        <SelectionOption onClick={(event) => { updateState(element) }} key={element} className={`roleselection-${index}`}>
                            <SelectionText className={`selection-${index}`}>{element}</SelectionText>
                        </SelectionOption>
                    )
                })}
            </SelectionBox>
        </CustomDropPemu2App>
    );
}


const CustomDropPemu2App = styled.div`
box-sizing: border-box;
    //width: 270px;
    width: 100%;
    position: relative;
    cursor: pointer;
`

const MainInputBox = styled.div`
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #84004C;
    background: #FFFFFF;
    border: none;
    background: #F5F2EC;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor:  "pointer";
    position: relative;
    z-index: 1;
`

const SingleText = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #676767;
    position: relative;
    z-index: 100;
    text-transform: capitalize;
    width: 100%;
    justify-content: center;
`

const SelectionBox = styled.div<{ dropState: boolean }>`
    box-sizing: border-box;
    position: absolute;
    top: 75%;
    width: 100%;
    
    //width: 270px;
    display: ${({ dropState }) => dropState ? "flex" : "none"};
    background: linear-gradient(to bottom ,#FFFFFF00 0% 30px, #FFFFFF 5% 100%);
    background: #FFFFFF;
    z-index: 1;
    border-left: 1px solid #84004C;
    border-right: 1px solid #84004C;
    border-bottom: 1px solid #84004C;
    border-collapse: collapse;
    border-radius: 0px 0px 10px 10px;
    user-select: none;
    
    flex-direction: column;
    padding: 0px 10px;
    padding-top: 10px;
    z-index: 2;
`

const SelectionOption = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 25px;
    //background-color: #FFFFFF;
    border-bottom: 1px solid #EDEDED;
    position: relative;
    width: 100%;
    padding: 25px 5px;

    &:hover{
        background-color: "#8d2874" ;
    }
`

const SelectionText = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #4F4F4F;
    text-transform: capitalize;
`

