import { rejects } from 'assert';
import axios from 'axios';
//import { Console } from 'console';
import { app } from '../../config/config'
//import { UserState } from './userSlice';

const BACKEND_URL = app.BACKEND_URL

interface UserState {
  sub: string,
  role: string,
  email: string,
  profileImage: string|undefined,
  username: string,
  channels: string[],
}

// export function requesUserInfo(token: string) {
export function requesUserInfo() {
  return new Promise<{ data: UserState }>((resolve, rejects) =>{

    const options = {
      method: 'GET',
      withCredentials: true,
      url: `${BACKEND_URL}/api/auth/myaccount`
    };

    axios(options)
    .then(function (response) {

        const data = {
          sub: response.data.sub,
          role: response.data.role,
          email: response.data.email,
          username: response.data.username,
          channels: response.data.channels,
          profileImage: response.data.profile_image ? response.data.profile_image : undefined,
        };

        resolve({ data });
    })
    .catch(function (error) {
        rejects('No possible authenticate');
    });

  });
}
