import React from "react";
import PurpleEvent from '../../assets/icons/purpleEvent.png';
import PenGray from '../../assets/icons/penGray.png';
import { SectionForm, SectionTitle, EventImageContainer, ImageInputType, EditButton, EmptyImageContainer, EventImage } from './eventStylingForm'
import { onFileChange } from '../../services/collections/imageToFile'
import {TImageFiles} from '../eventsList/eventForm'

interface widgetProps {
    eventData: any,
    updateEventAttributes: Function,
    updateImageFiles: Function,
    imageFiles:TImageFiles
}

export default function Event(props: widgetProps) {

    let { eventData, updateEventAttributes, updateImageFiles, imageFiles } = props;

    let updateEventImage = (event: any) => {
        let eventImageUrl = URL.createObjectURL(event.target.files[0])
        updateEventAttributes("EventImage", eventImageUrl)

        onFileChange(event).then((response: any) => {
            let temporalInfo: TImageFiles = { ...imageFiles }
            temporalInfo.EventImageData.file = response[0].file;
            temporalInfo.EventImageData.url = eventImageUrl;
            updateImageFiles(temporalInfo)
        }).catch((error) => {
            console.log("error: " + error)
        })
    }
    let clickEvent = () => { document.getElementById("EventImageFile")?.click() }


    let emptyImage = (
        <EmptyImageContainer onClick={clickEvent} clickable={true}>
            <img src={PurpleEvent} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )
    let eventImage = <EventImage src={eventData.EventImage} alt="EventImage" />;

    return (
        <SectionForm>
            <SectionTitle>Event image</SectionTitle>
            <EventImageContainer>
                {eventData.EventImage ? eventImage : emptyImage}
                {!eventData.EventImage ? "" : (
                    <EditButton onClick={clickEvent} disabled={false} state={false}>
                        Edit event image
                        <img src={PenGray} alt="edit" />
                    </EditButton>
                )}
                <ImageInputType type="file" id="EventImageFile" name="EventImageFile" onChange={updateEventImage} />
            </EventImageContainer>
        </SectionForm>
    );
}