import { AnyAction } from "@reduxjs/toolkit";
import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import WalletList from "../../components/walletsList/walletList";
import { getApiWallets, getApiWalletsStatus, getApiWalletsErrors, fetchWallets} from '../../services/wallets/walletsSlice'
import "./Wallets.css"
import PlusImage from '../../assets/icons/plus.png';
import { NavLink } from "react-router-dom";

interface widgetProps { identifier?: string }
export default function Wallets( props: widgetProps ) {
    const dispatch = useAppDispatch();
    const wallets = useSelector(getApiWallets);
    const walletsStatus = useSelector(getApiWalletsStatus);
    const walletsErrors = useSelector(getApiWalletsErrors);
    
    useEffect(()=>{
        if(walletsStatus == 'idle'){
            dispatch(fetchWallets())
        }
    }, [walletsStatus, dispatch])

    return(
        <div className="walletApp">
            <div className="wallet-main">
                <div className="wallets-main__NewWallet">
                    <NavLink to="/wallets/new"  className="newWallet-button">
                        <h3 className="newWallet-buttonTitle">Add New Wallet </h3>
                        <img src={PlusImage} alt="plusButton" className="newWallet-btnImg" />
                    </NavLink>
                </div>

                <div className="wallets-main__ListWallet">
                    <WalletList apiWallets={wallets}/>
                </div>
            </div>
        </div>
    );
}