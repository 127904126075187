import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import WalletForm from "../../components/walletsList/walletForm";
import { updateWalletInfo, uploadWalletFoto } from "../../services/wallets/walletsReq"
import { TWalletData, TImageFiles } from "../../components/walletsList/walletForm";

interface TfunctionParams {
    idWallet?: any,
    walletData: TWalletData,
    walletImageFiles: TImageFiles,
}

export default function EditWallet() {
    let navigate = useNavigate();

    const handleSave = (parameters: TfunctionParams) => {
        let { idWallet, walletData, walletImageFiles } = parameters
        let walletImageFile = walletImageFiles.WalletImageData.file;

        let payloadCreate = {
            "wallet_name": walletData.WalletName,
            "wallet_address": walletData.WalletAddress,
            "category": walletData.Category,
        }


        //==== Adding new wallet image
        if (walletImageFile) {
            let walletImageType =  walletImageFile.type.split("/").pop();
            let walletFotoData = {
                id: idWallet, 
                file_type: walletImageType, 
                image_name:  "wallet_image", 
                files: walletImageFile
            }
            uploadWalletFoto(walletFotoData);
        }

        //==== Updating wallet specs
        let walletDataPayload = {
            walletId: String(idWallet),
            data: payloadCreate
        }
        updateWalletInfo(walletDataPayload).then((response) => {
            navigate("/wallets")
        })
    }

    return (
        <WalletForm type="edit" handleSave={handleSave} />
    );
}