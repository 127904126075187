import { Event } from "isomorphic-ws";
import React, {useState} from "react";
import styled from "styled-components";
import SearchViolet from '../../assets/icons/searchViolet.png'

interface TProps{ searchBarText:string, setSearchBarText:Function }
export default function SearchBar(props:TProps) {
    const {searchBarText, setSearchBarText} = props;
    const onChangeHandler = (event:React.ChangeEvent<HTMLInputElement>) =>{setSearchBarText(event.target.value);}
    return(
        <SearchBarApp>
            <SearchInputText type="text" placeholder="Search" value={searchBarText} onChange={onChangeHandler}/>
            <SearchLogo src={SearchViolet} alt="search"/>
        </SearchBarApp>
    );
}


const SearchBarApp = styled.div`
    box-sizing: border-box;
    position: relative;
    //width: 100%;
    height: 40px;
    display: flex;
    
`

const SearchInputText = styled.input`
    box-sizing: border-box;
    
    font-family: "trade-gothic";
    font-size: 14px;
    width: 250px;
    border: 1px solid #F0EEDD;
    border-radius: 13px;
    padding: 10px;
    padding-right: 30px;
    color: #777777;
`

const SearchLogo = styled.img`
    box-sizing: border-box;
    position: absolute;
    width: 15px;
    object-fit: contain;
    right: 10px;
    top: 32%;
`