import React, { ChangeEvent, EventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import { applicationDataType } from '../../../services/apiSlice/apiTypes'
import { useApproveApplicationMutation, useRejectApplicationMutation } from "../../../services/apiSlice/apiSlice"
import { getPayment } from "../../../services/payments/payments.api";
import { ApplicactionStepsTypes } from "./Navigator"
import { PaymentDataType } from "../../../services/payments/payment.types";


import loadingGif from '../../../assets/gif/loading_2.gif'

interface TProps {
    paymentId: string
    changeStep: (step: ApplicactionStepsTypes) => void,
    cleanSelectedApply: () => void,
    closeModal: () => void,
    errorMsg: (msg: string) => void,
}

export default function ApplyModal(props: TProps) {
    const { paymentId, changeStep, cleanSelectedApply, closeModal, errorMsg } = props
    const [loading, setLoading] = useState(false)
    const [ paymentInfo, setPaymentInfo ] = useState<PaymentDataType|undefined>(undefined)

    function formatDate(date: string) {
        console.log(date)
        const dateToShow = new Date(date)
    
        const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
          year:  'numeric',
          month: 'short',
          day:   'numeric',
        });
    
        return longEnUSFormatter.format(dateToShow);
    }

    function formatDatePaid(date: string) {
        console.log(date)
        const dateToShow = new Date(date)
    
        const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
          year:  'numeric',
          month: 'short',
          day:   'numeric',
          hour: "numeric",
        minute: "numeric",
        });
    
        return longEnUSFormatter.format(dateToShow);
    }

    useEffect(() => {
        getPayment(paymentId)
        .then((resp) => {
          console.log(resp)
          setPaymentInfo(resp.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }, [])

    return (
        <ApplicationData>

        <Title> Payments <IdApplication>#{paymentId}</IdApplication> </Title>

        { paymentInfo ? 
        
        <Payments>
        { paymentInfo && paymentInfo.type === 'single' ?
            <Installment>
                <Due>
                Single payment
                </Due> 

                <DueTime>
                { paymentInfo &&
                    <PaidTime> { formatDatePaid(paymentInfo.pay_1_time) } </PaidTime>
                }
                </DueTime>

                <DueId>
                { paymentInfo && paymentInfo.pay_1_id ?
                    <PaidId> { paymentInfo.pay_1_id } </PaidId> :
                    '-'
                }
                </DueId>
            </Installment>
            :
            <>
          <Installment>
            <Due>
              1<Super>st</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[0] ? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_1_time) } </PaidTime> :
                formatDatePaid(paymentInfo.pay_1_time)
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_1_id ?
                <PaidId> { paymentInfo.pay_1_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              2<Super>nd</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[1] && paymentInfo.pay_2_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_2_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[0])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_2_id ?
                <PaidId> { paymentInfo.pay_2_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              3<Super>rd</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[2] && paymentInfo.pay_3_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_3_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[1])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_3_id ?
                <PaidId> { paymentInfo.pay_3_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              4<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[3] && paymentInfo.pay_4_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_4_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[2])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_4_id ?
                <PaidId> { paymentInfo.pay_4_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              5<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[4] && paymentInfo.pay_5_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_5_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[3])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_5_id ?
                <PaidId> { paymentInfo.pay_5_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              6<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[5] && paymentInfo.pay_6_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_6_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[4])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_6_id ?
                <PaidId> { paymentInfo.pay_6_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              7<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[6] && paymentInfo.pay_7_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_7_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[5])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_7_id ?
                <PaidId> { paymentInfo.pay_7_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              8<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[7] && paymentInfo.pay_8_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_8_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[6])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_8_id ?
                <PaidId> { paymentInfo.pay_8_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              9<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[8] && paymentInfo.pay_9_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_9_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[7])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_9_id ?
                <PaidId> { paymentInfo.pay_9_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              10<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[9] && paymentInfo.pay_10_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_10_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[8])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_10_id ?
                <PaidId> { paymentInfo.pay_10_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              11<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[10] && paymentInfo.pay_11_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_11_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[9])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_11_id ?
                <PaidId> { paymentInfo.pay_11_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>

          <Installment>
            <Due>
              12<Super>th</Super> payment
            </Due> 

            <DueTime>
              { paymentInfo && paymentInfo.payments_status[11] && paymentInfo.pay_12_time? 
                <PaidTime> { formatDatePaid(paymentInfo.pay_12_time) } </PaidTime> :
                formatDatePaid(paymentInfo.next_dues[10])
              }
            </DueTime>

            <DueId>
              { paymentInfo && paymentInfo.pay_12_id ?
                <PaidId> { paymentInfo.pay_12_id } </PaidId> :
                '-'
              }
            </DueId>
          </Installment>
          </>
        }

        </Payments>
        :
        <Loading>
            <LoadingIcon src={loadingGif} />
        </Loading>
        }



            {loading &&
                <Loading>
                    <LoadingIcon src={loadingGif} />
                </Loading>
            }

        </ApplicationData>
    )
}


const ApplicationData = styled.div`
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    color: #676767;
    font-family: 'trade-gothic';
    max-height: 650px;
    background: #F0EEDD;
    padding: 30px 70px 30px 70px;
    border-radius: 10px;
    text-align: left;
    min-width: 450px;
    min-height: 350px;
`

const Title = styled.div`
    font-style: normal;
    /* font-weight: 700; */
    font-size: 29px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
    margin: 5px;
    padding: 10px;
    text-align: left;
    width: 100%;
`

const IdApplication = styled.label`
    font-size: 20px;
    color: #676767;
`




const Payments = styled.div`
  margin: 10px 0px;
  
`

const Installment = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: auto;
`
  const Paid = styled.div`
    color: #84004C;
  `
    const PaidTime = styled.div`
        color: #84004C;
    `

  const Due = styled.div`
    width: 100px;
    text-align: left;
    /* padding-left: 40px; */
  `
    const Super = styled.div`
      vertical-align: super;
      font-size: 10px;
      font-weight: 500;
      width: 15px;
      display: inline-flex;
      margin-left: 2px;
    `

  const DueTime = styled.div`
    font-family: 'trade-gothic', sans-serif;
    width: 135px;
    color: #777777; 
  `

  const DueId = styled.div`
    font-family: 'trade-gothic', sans-serif;
    width: 195px;
    color: #777777; 
  `
    const PaidId = styled.div`
        color: black;
    `

const Loading = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #ffffffbb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LoadingIcon = styled.img`
    height: 70px;
`
