import React, { useState } from "react";
import "./WalletList.css"
import WalletCard from "./walletCard";

interface TWalletCard {
    id: string,
    WalletName: string,
    WalletImage: string,
    Category: string
    WalletAddress: string
}
interface TWalletList { apiWallets: Array<any> }
export default function WalletList(props: TWalletList) {

    let listWallets = props.apiWallets ? props.apiWallets.map((cardInfo: TWalletCard, index: number) => {
        return (
            <WalletCard cardInfo={cardInfo} key={String(index)} />
        )
    }) : '';

    return (
        <div className="walletListApp">
            {listWallets}
        </div>
    );
}