import React, {useState} from "react";
import styled from "styled-components";
import Day3 from './Day3';

export const monthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "Octuber",
    "November",
    "December"
]

export const dayArr = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export const day31MonthArr = [
    "January",
    "March",
    "May",
    "July",
    "August",
    "October",
    "December"
]

export const getDate = (d: Date) => {
    let indexMonth = d.getMonth();
    let month = monthArr[indexMonth];
    let indexDay = d.getDay();
    let day = dayArr[indexDay];
    let date = d.getDate();
    let year = d.getFullYear();

    let diferenceDays = (date % 7)
    let startingDay = (indexDay - diferenceDays + 1) % 7;
    if (diferenceDays > indexDay) startingDay = (indexDay + 8 - diferenceDays) % 7;
    let startOfMonth = dayArr[startingDay]

    let totalDays = 30;
    if (month == "February" && year % 4 !== 0) totalDays = 28;
    else if (month == "February" && year % 4 == 0) totalDays = 29;
    else if (day31MonthArr.includes(month)) totalDays = 31;

    return { indexMonth, month, indexDay, day, date, year, startingDay, startOfMonth, totalDays }
}


interface TCalendarResult{
    StartDate: string|undefined,
    EndDate: string|undefined,
    OneDate: string|undefined,
    BetweenDays: Array<any>|undefined
}

interface TCalendar{
    inputDate: string,
    mode: "RangeDays"|"OneDayPick",
    initialStatesForDates: any,
    resultState: TCalendarResult
    setResultState: Function
}

interface TDay{
    date: string,
    state: "Start"|"End"|"Between"|"Normal"|"Selected"|"Unique"|"Disabled"|undefined,
    customStyle: string|undefined|null,
    selectable: boolean,
    clicked: Function
}

function getBetweenDays(startDate: any, endDate: any) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate < endDate) {
        let auxDate = new Date(currentDate);
        let [aMonth, aDay, aYear] = auxDate.toLocaleDateString().split("/")
        dates.push(`${aDay}/${aMonth}/${aYear}`);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    if (dates.length) dates.shift();
    return dates;
}

export const oldDateCheck = (selected: string, referer: string) => {
    let [selectedDay, selectedMonth, selectedYear] = selected.split("/")
    let [referernDay, refererMonth, refererYear] = referer.split("/")
    if ((+selectedDay < +referernDay && +selectedMonth <= +refererMonth && +selectedYear === +refererYear) ||
        (+selectedMonth < +refererMonth && +selectedYear <= +refererYear) ||
        +selectedYear < +refererYear) return true;
    return false;
}

export default function Calendar(props:TCalendar) {
    let {inputDate, mode, initialStatesForDates, setResultState} = props;
    let {StartDate, EndDate, OneDate, BetweenDays} = props.resultState;

    const [fDay, fMonth, fYear] = inputDate.split("/");
    const fixedDate = `${fMonth}/${fDay}/${fYear}`
    const d = new Date(fixedDate);
    const dateInfo = getDate(d);

    const clickedDay = (date:string) => {
        if(mode === "OneDayPick"){
            let newState = { 
                StartDate: undefined, 
                EndDate: undefined,
                BetweenDays: [],
                OneDate: date
            }
            setResultState(newState)
        }

        if(mode === "RangeDays"){
            let newStartDate;
            let newEndDate;
            let newBetweenDays;
            
            if(!StartDate || EndDate){
                newStartDate = date;
                newEndDate = undefined;
                newBetweenDays = [];  
            }

            if(StartDate && !EndDate) {
                newStartDate = StartDate;
                newEndDate = date;
                const [sDay, sMonth, sYear] = newStartDate.split("/");
                const [eDay, eMonth, eYear] = newEndDate.split("/");
                const fixedStartDate = `${sMonth}/${sDay}/${sYear}`
                const fixedEndDate = `${eMonth}/${eDay}/${eYear}`
                newBetweenDays = getBetweenDays(new Date(fixedStartDate), new Date(fixedEndDate));
            }

            if(StartDate && date === StartDate && !EndDate){
                newStartDate = undefined;
                newEndDate = undefined;
                newBetweenDays =undefined;
            }
            
            let newState = { 
                StartDate: newStartDate, 
                EndDate: newEndDate,
                BetweenDays: newBetweenDays,
                OneDate: undefined
            }
            setResultState(newState)
        }
    }

    let daysElements = [];
    let dayElement;
    let limitStart = StartDate;
    let limitEnd = null;
    for(let i = 0; i < dateInfo.totalDays + dateInfo.startingDay; i ++){
        if(i < dateInfo.startingDay) dayElement = <div key={i}>{" "}</div>
        else {
            let day = i- dateInfo.startingDay +1;
            let month = dateInfo.indexMonth + 1;
            let year = dateInfo.year;
            let dateForDay = `${day}/${month}/${year}`;
            
            let dayProps:TDay = {
                date: dateForDay,
                state: "Normal",
                customStyle: null,
                selectable: true,
                clicked: clickedDay
            }

            
            if(initialStatesForDates && initialStatesForDates.hasOwnProperty(dateForDay)){
                dayProps.customStyle = initialStatesForDates[dateForDay];
                if(StartDate && !EndDate && !limitEnd) limitEnd = dateForDay;
            }

            if(mode === "OneDayPick" && dateForDay === OneDate) dayProps.state = "Selected";
            if(mode === "RangeDays"){
                if((!StartDate || !EndDate) && (dateForDay === StartDate || dateForDay === EndDate)) dayProps.state = "Selected"
                if(dateForDay === StartDate && EndDate) dayProps.state = "Start";
                if(dateForDay === EndDate && StartDate) dayProps.state = "End";
                if(BetweenDays?.includes(dateForDay)) dayProps.state = "Between";
                if((limitStart && oldDateCheck(dateForDay, limitStart)) || limitEnd && oldDateCheck(limitEnd, dateForDay)) {
                    dayProps.state = "Disabled";
                    dayProps.selectable = false;
                }
            }

            dayElement = <Day3 {...dayProps} key={i}>{day}</Day3>;
        }
        daysElements.push(dayElement)
    }


    return(
        <CalendarApp>
            <CalendarBox>
                <WeekDays>
                    <div>Su</div>
                    <div>Mo</div>
                    <div>Tu</div>
                    <div>We</div>
                    <div>Th</div>
                    <div>Fr</div>
                    <div>Sa</div>
                </WeekDays>
                <NumberDays>
                    {daysElements}
                </NumberDays>
                
            </CalendarBox>
        </CalendarApp>
    );
}


const CalendarApp = styled.div`
    overflow-x: hidden;
`

const CalendarBox = styled.div`
    font-family: 'trade-gothic';
`

const WeekDays = styled.div`
    overflow-x: hidden;
    //padding: 5px 0px 5px 5px;
    display: grid;
    grid-template-columns: repeat(7, minmax(32px, 1fr));
    grid-gap: 0px;
    & div{
        font-family: 'gotham';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #777777;
    }

`

const NumberDays = styled.div`
    font-family: 'gotham';
    font-size: 12px;
    //padding: 5px 0px 5px 5px;
    //padding: 5px 15px;
    display: grid;
    grid-template-columns: repeat(7, minmax(32px, 1fr));
    grid-gap: 0px;
`