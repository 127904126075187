import axios from 'axios'
import { app } from '../../config/config';
import { AxiosResponse, AxiosError } from 'axios'
import { PaymentDataType } from './payment.types';
// import { Lcollection } from './lockerTypes'

const BACKEND_URL = app.BACKEND_URL
// let BACKEND_URL = 'https://vinereserveclub.smartssi.net'
// let BACKEND_URL = 'https://preprod-vinereserveclub.smartssi.net'
// let BACKEND_URL = 'https://vinereserveclub.com'

export function getPayment(paymentId: string) {
  const axiosOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/api/admin/payments/${paymentId}`
  };

  return new Promise<AxiosResponse<PaymentDataType>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to authenticate')
    })
  })
}
