import React, {ChangeEvent, EventHandler, useState} from "react";
import styled from "styled-components";
import { TLocalSuscribers } from "../../views/usersManagement/Users";
import SwitchButton2 from "../switchButton/switchButton2";
import CustomDropPemu2 from "../modals/customDropMenu2";
import { click } from "@testing-library/user-event/dist/types/convenience";

interface TProps{
    userData:TLocalSuscribers,
    updateUserData: Function
}

export default function UserForm(props:TProps) {
    const {userData, updateUserData} = props;
    const [dropFlag, setDropFlag] = useState(false);

    let updateField = (event:string|boolean, section:any) =>{
        let newUserData:any = {...userData};
        newUserData[section] = event;
        updateUserData(newUserData);
    }

    let clickedForm = (event:any) => {
        let clickedClass = event.target.className;
        if(!clickedClass.includes("Custom")){
            setDropFlag(false)
        }
    }


    const customDropInput = {
        selected: userData.UserRole,
        type: "text",
        options: ["admin", "user"],
        updateSelected: (data:any) => {updateField(data, "UserRole")},
        dropFlag: dropFlag,
        updateDropFlag: (value:boolean) => {setDropFlag(value)}
    }
    return(
        <UserFormApp onClick={clickedForm}>
            <PairInput>
                <NormalLabel>Name</NormalLabel>
                <TextInput value={userData.UserName||""} onChange={(event)=>{updateField(event.target.value, "UserName")}} readOnly/>
            </PairInput>

            <PairInput>
                <NormalLabel>Email</NormalLabel>
                <TextInput value={userData.Email||""} readOnly/>
            </PairInput>

            <PairInput>
                <NormalLabel>Phone Number</NormalLabel>
                <TextInput value={userData.PhoneNumber||""} readOnly/>
            </PairInput>

            <PairInput>
                <NormalLabel>User Role</NormalLabel>
                <CustomDropPemu2 {...customDropInput}/>
            </PairInput>

            <EnableSection>
                <EnabledText>{userData.Status ? "Enabled" : "Disabled"}</EnabledText>
                <SwitchButton2 state={userData.Status} size="20" changeState={()=>{updateField(!userData.Status, "Status")}}/>
            </EnableSection>
        </UserFormApp>
    );
}


const UserFormApp = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #676767;
    font-family: 'trade-gothic';
    gap: 20px;
`

const PairInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px
`

const NormalLabel = styled.label`
    text-align: left;
    font-size: 20px;
`

const TextInput = styled.input`
    padding: 10px 8px;
    border-radius: 8px;
    border: 1px solid #84004C;
    color: #676767;
    font-family: 'trade-gothic';
    font-size: 20px;
`

const TextInputSelect = styled.select`
    
`

const TextInputOption = styled.option`
    
`

const EnableSection = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
`

const EnabledText = styled.div`
    color: #9B9B9B;
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin: 0px;
`