import React, { useEffect, useState, useContext } from "react";
import "./Reservations.css"
import { useSelector } from "react-redux";
import FilterSection from "../../components/reservations/FilterSection";
import ReservationsButtonSection from "../../components/reservations/ReservationsButtonSection";
import ReservationsTableSection from "../../components/reservations/ReservationsTableSection";
import { UsedDateProvider } from '../../context/usedDatesContext2'
import { FilterDateProvider } from '../../context/filterDateContext'
import { useAppDispatch } from "../../app/hooks";
import { fetchReservations, getApiReservations, clearReservations } from "../../services/reservations/reservationsSlice";
import CurrentDate from '../../context/currentDateContext';
import UsedDateContex from '../../context/usedDatesContext2';

const recursionSearchClass: any = (target: any, word: string, deep: number) => {
    if (deep <= 0 || !target) return false;
    if (target.className.includes(word)) return true;
    else return recursionSearchClass(target.parentElement, word, deep - 1)
}

export default function Reservations() {

    const dispatch = useAppDispatch();
    const [section, setSection] = useState("Requests");
    const [modalCalendarFlag, setModalCalendarFlag] = useState(false);
    const currentDateContext = useContext(CurrentDate);
    let usedDateContex = useContext(UsedDateContex);

    //const [localData, setLocalData] = useState([]);
    const localData = useSelector(getApiReservations);

    const [datesFilters, setDatesFilters] = useState({ startDate: "", endDate: "" });
    const [searchBarText, setSearchBarText] = useState("")
    const changeSection = (newSection: string) => { setSection(newSection) }

    const closeCalendarModal = (event: any) => {
        let nameInClass = recursionSearchClass(event.target, "calendar-modal", 10);
        if (modalCalendarFlag && !nameInClass) {
            setModalCalendarFlag(false);
        }
    }

    const filterSectionParams = {
        modalCalendarFlag: modalCalendarFlag,
        setModalCalendarFlag: (value: boolean) => { setModalCalendarFlag(value) },
        datesFilters: datesFilters,
        setDatesFilters: (value: any) => { setDatesFilters(value) },
        searchBarText: searchBarText,
        setSearchBarText: (value:string) => { setSearchBarText(value) }
    }

    const requestReservations = () =>{
        if (datesFilters.startDate.length && datesFilters.endDate.length) dispatch(fetchReservations(datesFilters));
        else dispatch(clearReservations(""))
    }

    useEffect(() => {
        requestReservations();
    }, [datesFilters])

    //cleaning currentContext
    useEffect(() => {
        usedDateContex.resetDates();
        currentDateContext.updateData({
            StartDate: "",
            EndDate: "",
            Persons: 1,
            OneDay: false,
            Bungalows: 1,
            MaxDate: "",
            BetweenDays: []
        })
    },[])

    
    return (
        <div className="reservationsApp" onClick={closeCalendarModal}>
            <div className="reservations-titleSection">
                <h1 className="reservations-title">Reserve List</h1>
            </div>

                <FilterDateProvider>
                    <FilterSection {...filterSectionParams} />
                    <ReservationsButtonSection section={section} changeSection={changeSection} />
                    <ReservationsTableSection section={section} data={localData} textFilter={searchBarText} requestReservations={requestReservations}/>
                </FilterDateProvider>
        </div>
    );
}