import React, {useState} from "react";
import ReadtDOM from 'react-dom'
import styled from 'styled-components'
import { EditButton, EmptyImageContainer } from '../FormElements/stylingForm';
import LeftArrowImage from '../../assets/icons/leftArrow.png';
import RightArrowImage from '../../assets/icons/rightArrow.png';
import PurpleMountain from '../../assets/icons/purpleMountain.png';
import PenGray from '../../assets/icons/penGray.png';
import ImageOptionsModal from '../modals/imageOptionsModal'

interface widgetProps { 
    images: Array<string>,
    section: string,
    updateImages: Function,
    customDisabled: boolean 
}
export default function SliderCardBox( props: widgetProps ) {
    
    let [imageIndex, setImageIndex] = useState(0);
    let [imageModalEnabled, setImageModalEnabled] = useState(false);
    

    let updateImageIndex = (event:any) => {
        let selectedIndex = Number(event.target.id.split("-").pop());
        setImageIndex(selectedIndex);
    }

    let editCurrentImage = () => {setImageModalEnabled(true)}
    
    let goPrevImage = () => {
        let prevIndex = imageIndex - 1;
        if(prevIndex < 0) prevIndex = props.images.length - 1;
        setImageIndex(prevIndex)
    }

    let goNextImage = () => {
        let nextIndex = imageIndex + 1;
        if(nextIndex >= props.images.length) nextIndex = 0;
        setImageIndex(nextIndex)
    }

    let updateImagesList = (elements:Array<string>) => {
        props.updateImages([...elements])
    }

    
    let emptyImage = (
        <EmptyImageContainer onClick={editCurrentImage}>
            <img src={PurpleMountain} alt="EmptyImage" width="60px" height="60px"  />
        </EmptyImageContainer>
    );

    let carrouselImages = props.images.map((image, index) => { return(
        <ListedImage src={image} alt={`image-${index}`} key={`image-${index}`} index={imageIndex}/>
    )})

    let customEdit = !props.images.length? '': (
        <EditButton onClick={editCurrentImage} state={props.customDisabled} disabled={props.customDisabled}>
            Edit {props.section} image
            <img src={PenGray} alt="edit" />
        </EditButton>
    );
    
    let dotList = props.images.map((image, index) => { return(
        <Dot index={index} actived={index===imageIndex} onClick={updateImageIndex} key={`dot-${index}`} id={`dot-${index}`}/>
    )})
    

    return(
        <SliderMainBox>
            {imageModalEnabled && ReadtDOM.createPortal(<ImageOptionsModal images={props.images} updateImages={updateImagesList} updateModalState={()=>{setImageModalEnabled(false)}} section={props.section}/>, document.getElementById("modals") as Element)}
            
            {props.images.length > 1 && <LeftArrow src={LeftArrowImage} onClick={goPrevImage}/>}
            
            <ImageMainBox>
                {props.images.length? <CarrouselContainer index={imageIndex} amount={props.images.length}>{carrouselImages}</CarrouselContainer>: emptyImage}
                {customEdit}
            </ImageMainBox>
            
            {props.images.length > 1 && <RightArrow src={RightArrowImage} onClick={goNextImage}/>}
            
            {props.images.length > 1 && <DotsSection>{dotList}</DotsSection>}

        </SliderMainBox>
    );
}

const SliderMainBox = styled.div`
    box-sizing: border-box;
    position: relative;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 350px;
`

const LeftArrow = styled.img`
    box-sizing: border-box;
    position: absolute;
    bottom: 50%;
    left: -18px;
    z-index: 1;
    width: 10px;
    cursor: pointer;
`

const ImageMainBox = styled.div`
box-sizing: border-box;
    position: relative;
    overflow: hidden;
    height: 240px;
    margin: 0px auto;
    width: 350px;
    border-radius: 10px;
    background: #F0F0F0;
    display: inline;
    margin-bottom: 12px;
`

const CarrouselContainer = styled.div<{index:number, amount:number}>`
    width: ${({amount}) => `${amount*350}px`};;
    position: relative;
    right: ${({index}) => `${index*350}px`};
    transition: all 0.3s linear;
`
const ListedImage = styled.img<{index:number}>`
    box-sizing: border-box;
    display: inline;
    margin: 0;
    box-sizing: border-box;
    height: 240px;
    width: 350px;
    object-fit: cover;
    position: relative;
`

const RightArrow = styled.img`
    box-sizing: border-box;
    position: absolute;
    bottom: 50%;
    right: -18px;
    z-index: 1;
    width: 10px;
    cursor: pointer;
`

const DotsSection = styled.div`
box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 4px;
`
const Dot = styled.div<{actived:boolean, index:number}>`
box-sizing: border-box;
    width: 8px;
    height: 8px;
    border: none;
    border-radius: 50%;
    background: ${({actived, index}) => actived? "#830F5B": "#EDEDED"};
    transition: all 0.1s linear;
    cursor: pointer;
`