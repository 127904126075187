import React, { ChangeEvent, EventHandler, useState } from "react"
import styled from "styled-components"

import { applicationDataType } from '../../../services/apiSlice/apiTypes'
import checkedIcon from "../../../assets/icons/checked.png"

interface TProps {
    applyData: applicationDataType
}

export default function ApplyData(props: TProps) {
    const { applyData } = props;

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    function getDateNow(date_ac: number) {
        const d = new Date(date_ac);

        const date = d.getDate()
        const month = monthNames[d.getMonth()]
        const year = d.getFullYear()

        return `${month} ${date}, ${year}`;
    };

    return (
        <Data className='Data'>
            <GridInput>
                {
                    applyData.firstName || applyData.lastName ?
                        <PairInput>
                            <NormalLabel><SubTitle>Name: </SubTitle>{applyData.firstName + ' ' + applyData.lastName || ""}</NormalLabel>
                        </PairInput>
                        : null
                }
                {applyData.email ?
                    <PairInput>
                        <NormalLabel><SubTitle>Email: </SubTitle>{applyData.email || ""}</NormalLabel>
                    </PairInput>
                    : null
                }
                {applyData.phone ?
                    <PairInput>
                        <NormalLabel><SubTitle>Phone Number: </SubTitle>{applyData.phone || ""}</NormalLabel>
                    </PairInput>
                    : null
                }
                {applyData.twitter ?
                    <PairInput>
                        <NormalLabel><SubTitle>Twitter: </SubTitle>{applyData.twitter || ""}</NormalLabel>
                    </PairInput>
                    : null
                }
                {applyData.instagram ?
                    <PairInput>
                        <NormalLabel><SubTitle>Instagram: </SubTitle>{applyData.instagram || ""}</NormalLabel>
                    </PairInput>
                    : null}
                {applyData.linkedIn ?
                    <PairInput>
                        <NormalLabel><SubTitle>Linkedin: </SubTitle>{applyData.linkedIn || ""}</NormalLabel>
                    </PairInput>
                    : null}
                {applyData.generalLocation ?
                    <PairInput>
                        <NormalLabel><SubTitle>General Location: </SubTitle>{applyData.generalLocation || ""}</NormalLabel>
                    </PairInput>
                    : null}

                <PairInput>
                    <NormalLabel><SubTitle>Created date: </SubTitle>{applyData.created_at ? getDateNow(applyData.created_at) : getDateNow(1651974436744)}</NormalLabel>
                </PairInput>                    

                { applyData.revision_date &&
                    <PairInput>
                        <NormalLabel><SubTitle>Revision date: </SubTitle>{ getDateNow(applyData.revision_date) }</NormalLabel>
                    </PairInput>
                }

                {applyData.paid_date &&
                    <PairInput>
                        <NormalLabel><SubTitle>Paid date: </SubTitle> { getDateNow(applyData.paid_date) } </NormalLabel>
                    </PairInput>
                }

            </GridInput>

            <SubTitle>Intended Uses</SubTitle>
            <InteredUses>
                {applyData.usesVineyard == true ?
                    <div>
                        <LabelUses> Vineyard </LabelUses>
                    </div>
                    : null}
                {applyData.usesPrivateRooms == true ?
                    <div>
                        <LabelUses> Private Rooms </LabelUses>
                    </div>
                    : null}
                {applyData.usesEvents == true ?
                    <div>
                        <LabelUses> Events </LabelUses>
                    </div>
                    : null}
                {applyData.usesNetworking ?
                    <div>
                        <LabelUses> Networking </LabelUses>
                    </div>
                    : null}
                {applyData.usesGolfCourse ?
                    <div>
                        <LabelUses> Golf Course </LabelUses>
                    </div>
                    : null}
                {applyData.usesSportsCourts ?
                    <div>
                        <LabelUses> Sports Courts </LabelUses>
                    </div>
                    : null}
                {applyData.usesSpaPool ?
                    <div>
                        <LabelUses> Spa & Pools </LabelUses>
                    </div>
                    : null}
                {applyData.usesArt ?
                    <div>
                        <LabelUses> Art </LabelUses>
                    </div>
                    : null}
            </InteredUses>

            {/* <div>
                {applyData.wallet ?
                    <SubTitleWhitelist>Whitelist</SubTitleWhitelist>
                    :
                    null
                }
            </div> */}

            {<div>
                <GridRadioType>
                    <div>
                        <SubTitle>Membership type</SubTitle>
                        {
                            applyData.membershipType == "founder" ?
                            <NormalLabel> Founder</NormalLabel>
                            : applyData.membershipType == "especiale" || applyData.membershipType == "Especiale" ?
                            <NormalLabel> Especiale</NormalLabel>
                            : null
                        }
                    </div>
                </GridRadioType>
            </div>
            }

            {/* {applyData.wallet ?
                <PairInput>
                    <NormalLabel><SubTitle>Wallet: </SubTitle>{applyData.wallet || ""}</NormalLabel>
                </PairInput>
                : null
            } */}
            {applyData.notes ?
                <PairInput>
                    <NormalLabel><SubTitle>Notes: </SubTitle>{applyData.notes || ""}</NormalLabel>
                </PairInput>
                : null}
            {applyData.referalCode ?
                <PairInput>
                    <NormalLabel><SubTitle>Referrer: </SubTitle>{applyData.referalCode || ""}</NormalLabel>
                </PairInput>
                : null}
        </Data>

    )
}

const Data = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: space-evenly;
    gap: 20px;
    max-height: 380px;
    padding: 10px 0px;
    width: 600px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 15px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #84004C;
        border-radius: 10px;
    }
`

const InteredUses = styled.div`
    /* display: grid;
    grid-gap: 15px;
    grid-template-columns: 18% 18% 18% 18% 18%; */
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    gap: 30px;
`

const GridInput = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 90%;
`

const GridRadioType = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 95%;
`

const LabelUses = styled.label`
    font-size: 18px;
    background-color: #84004C;
    color: #fff;
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
`

const PairInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
const SubTitle = styled.strong`
    color: #84004C;
    font-size:23px;
`

const SubTitleWhitelist = styled.strong`
    color: #84004C;
    font-size:26px;
`

const NormalLabel = styled.label`
    text-align: left;
    font-size: 23px;
`