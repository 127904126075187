import axios, { AxiosResponse } from 'axios';
import { app } from '../../config/config'
import { TWalletData } from '../../components/walletsList/walletForm';
import CollectionList from '../../components/collection/collectionList';
let BACKEND_URL = app.BACKEND_URL;


function showIncConsole(title: string, data: any) {
    console.log(`\n==== ${title} ====`)
    console.log(data)
}

let sampleData = {
    data: [{
        id: "1",
        WalletName: "WineCard",
        Category: "Wine",
        WalletAddress: "555112131545",
        WalletImage: "https://i3.cpcache.com/merchandise/558_300x300_Front_Color-Black.jpg?Size=NA&AttributeValue=NA&c=True&region={%22name%22:%22FrontCenter%22,%22width%22:4.23,%22height%22:3.384,%22alignment%22:%22MiddleCenter%22,%22orientation%22:0,%22dpi%22:150,%22crop_x%22:0,%22crop_y%22:0,%22crop_h%22:450,%22crop_w%22:600,%22scale%22:0,%22template%22:{%22id%22:101872649,%22params%22:{}}}%20&Filters=[{%22name%22:%22background%22,%22value%22:%22ddddde%22,%22sequence%22:2}]",
        Validated: true
    }, {
        id: "2",
        WalletName: "MemberCard",
        Category: "Membership",
        WalletAddress: "825112131545",
        WalletImage: "",
        Validated: true
    }
    ]
}


export interface TWalletFormated{
    id: string,
    WalletName: string,
    WalletImage: string,
    Category: string,
    WalletAddress: string
}
interface WalletsState {
    registered_by: string,
    id_item: string,
    wallet_address: string,
    category: string
    wallet_name: string,
    createdAt: number,
    image_url?: string,
    //status: string,
}
export async function walletsRequest() {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/wallets`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<{ data: Array<WalletsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data = response.data.items.map((element: WalletsState) => {
                    //console.log(element)
                    return {
                        id: element.id_item,
                        WalletName: element.wallet_name,
                        WalletImage: element.image_url || "",
                        Category: element.category,
                        WalletAddress: element.wallet_address
                    }
                })
                resolve({ data })
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}
export async function localWalletsRequest() {
    return new Promise<{ data: Array<any> }>((resolve, rejects) => {
        resolve(sampleData)
    })
}



function formatWalletApiToLocal<TWalletData>(data: any) {
    let walletFormated = {
        WalletImage: data.image_url || "",
        WalletName: data.wallet_name,
        WalletAddress: data.wallet_address,
        Category: data.category,
        Validated: data.wallet_status === undefined ? true : data.wallet_status
    }
    return walletFormated
}
interface TGetWalletInfo {

}
export function getWalletInfo(walletId: string) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/wallets/${walletId}`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<TWalletData>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data: TWalletData = formatWalletApiToLocal(response.data)
                resolve(data)
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}
export async function localGetWalletInfo(walletId: string) {
    return new Promise<any>((resolve, rejects) => {
        let sampleWallet = sampleData.data.filter((wallet: any) => wallet.id === walletId)[0];
        resolve(sampleWallet)
    })
}



interface TUpdateWalletInfo {
    walletId: string,
    data: {
        "wallet_name": string,
        "wallet_address": string,
        "category": string,
    }
}
export function updateWalletInfo({ walletId, data }:TUpdateWalletInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/wallets/${walletId}`,
        method: "PUT",
        data: data,
        withCredentials: true
    }

    return new Promise<{ data: Array<WalletsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}


export interface TResponseCreate {
    wallet_id: string,
    result: string
}
interface TCreateWalletInfo {
    data: {
        "wallet_name": string,
        "wallet_address": string,
        "category": string,
    }
}
export function createWalletInfo({data}: TCreateWalletInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/wallets`,
        method: "POST",
        withCredentials: true,
        data: data
    }

    return new Promise<AxiosResponse<TResponseCreate>>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}




interface TgetPresignedUrlProps {
    wallet_id: string,
    file_type: string,
    image_name: string,
    token_info?: any
}
interface TUploadWalletFoto {
    id: string | undefined,
    file_type: string,
    image_name: string,
    files: any
}
export function uploadWalletFoto(data: TUploadWalletFoto) {
    const { id, file_type, image_name, files } = data
    const wallet_id = id;


    function getPresignedUrl(props: TgetPresignedUrlProps) {
        const axiosOptions = {
            url: `${BACKEND_URL}/api/wallets`,
            method: 'POST',
            data: {
                type: "uploadImage",
                ...props,
            },
            withCredentials: true,
        }

        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                    reject('No possible to update inspection')
                })
        })
    }


    function updateInspeccion(toUpdate: any) {
        const axiosOptions = {
            method: 'POST',
            data: {
                type: "updateInspeccion",
                ...toUpdate,
            },
            withCredentials: true,
            url: `${BACKEND_URL}/api/wallets`
        };

        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                    reject('No possible to update inspection')
                })
        })
    }


    function uploadImage(file: File | Blob, slug: string, type: string) {

        return new Promise<any>((resolve, reject) => {
            fetch(slug, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                body: file
            })
                .then(data => {
                    resolve({ result: 'success' })
                })
                .catch((error) => console.log('userInfo error', error));
        })
    }



    if (wallet_id && file_type) {
        getPresignedUrl({ wallet_id, file_type, image_name })
            .then((resp: any) => {

                uploadImage(files, resp.urlS3Image, file_type)
                    .then(() => {

                        updateInspeccion({ task_id: wallet_id, [`${image_name}`]: resp.urlS3Image })
                            .then((respon) => {
                                let chainParams = {
                                    "getPresignedUrl": { wallet_id, file_type, image_name },
                                    "uploadImage": [files, resp.urlS3Image, file_type],
                                    "updateInspeccion": { task_id: wallet_id, [`${image_name}`]: resp.urlS3Image, }
                                }
                                //showIncConsole("Successfull uploadFoto cycle", chainParams)
                                return true;
                            })
                    })
            })
    }
}


export function validateWalletAddress(walletData: any) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/metamask`,
        data: walletData,
        method: "POST",
        withCredentials: true
    }

    return new Promise<any>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}