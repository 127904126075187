import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Approved from '../../assets/icons/approved.png'
import PenGray from '../../assets/icons/penGray.png'
import BlackTrash from '../../assets/icons/blackTrash.png'
import './ReservationsTableSection.css'
import ModalConfirmation from "../modals/modalConfimation";
import { approveReservation, rejectReservation } from "../../services/reservations/reservationsReq"

interface TmodalsInfo {
    RejectReserve: any,
    RejectComplete: any,
    ApproveReserve: any,
    ApproveComplete: any,

}
interface TReservationData {
    id: string,
    startDate: string,
    endDate: string,
    email: string,
    guests: number,
    bungalows: number,
    status?: string,
    oneDay: boolean
}
interface TProps { section: String, data: any, textFilter: string, requestReservations: Function }

export default function ReservationsTableSection(props: TProps) {
    const { section, data, textFilter, requestReservations } = props;
    const navigate = useNavigate();
    let [modalState, setModalState] = useState(false);
    let [modalParams, setModalParams] = useState({ closeModal: () => { } });

    let dataFiltered: any = [];
    if (data && data.length) {
        dataFiltered = textFilter.length ? data.filter((element: TReservationData) => { return element.email.includes(textFilter) }) : data;
        switch (section) {
            case "Requests":
                dataFiltered = dataFiltered.filter((element: TReservationData) => { return element.status === "requested" });
                break;
            case "Checked":
                dataFiltered = dataFiltered.filter((element: TReservationData) => { return element.status === "confirmed" });
                break;
            case "Canceled":
                dataFiltered = dataFiltered.filter((element: TReservationData) => { return element.status === "canceled" || element.status === "closed" });
                break;
        }

    }


    const opentValidatedOptionModal = (modalsInfoKey: keyof TmodalsInfo, idSelected?: any) => {

        switch (modalsInfoKey) {

            case "RejectComplete":
                rejectReservation(idSelected).then(response => {
                    let selectedModalParams = modalsInfo[modalsInfoKey];
                    setModalParams(selectedModalParams);
                })
                break;

            case "ApproveComplete":
                approveReservation(idSelected).then(resposne => {
                    let selectedModalParams = modalsInfo[modalsInfoKey];
                    setModalParams(selectedModalParams);
                })
                break;
        }
    }



    const closeModal = () => {
        setModalState(false);
    }
    const closeModalAndUpdate = () => {
        setModalState(false);
        requestReservations();
    }

    let modalsInfo = {
        RejectReserve: {
            title: "REJECT RESERVE?",
            message: "This action cannot be undone",
            acceptText: "REJECT",
            cancelText: "RETURN",
            confirmModal: () => { opentValidatedOptionModal("RejectComplete") },
            closeModal: closeModal
        },
        RejectComplete: {
            title: "RESERVE DELETED",
            message: "The reserve will no longer see on the list",
            acceptText: "OK",
            confirmModal: closeModalAndUpdate,
            closeModal: closeModalAndUpdate
        },
        ApproveReserve: {
            title: "APPROVE RESERVE?",
            message: "The dates will be added to the calendar",
            acceptText: "APPROVE",
            cancelText: "RETURN",
            confirmModal: () => { opentValidatedOptionModal("ApproveComplete") },
            closeModal: closeModal
        },
        ApproveComplete: {
            title: "RESERVE APPROVED",
            message: "The dates will be added to the calendar",
            acceptText: "OK",
            confirmModal: closeModalAndUpdate,
            closeModal: closeModalAndUpdate
        }
    }

    let openInitialModal = (reserveId: string, modalsInfoKey: keyof TmodalsInfo) => {
        let selectedModalParams = modalsInfo[modalsInfoKey]
        if (modalsInfoKey === "ApproveReserve") selectedModalParams.confirmModal = () => { opentValidatedOptionModal("ApproveComplete", reserveId) }
        if (modalsInfoKey === "RejectReserve") selectedModalParams.confirmModal = () => { opentValidatedOptionModal("RejectComplete", reserveId) }

        setModalState(true);
        setModalParams(selectedModalParams);
    }


    return (
        <ReservationsTableSectionApp>
            {modalState && <ModalConfirmation {...modalParams} />}
            <table className="reservations-table">
                <tbody>
                    <tr>
                        <th className="reservations-t-date">Start Date</th>
                        <th className="reservations-t-date">End Date</th>
                        <th className="reservations-t-email">Email</th>
                        <th className="reservations-t-dynamic">Nº of Guests</th>
                        <th className="reservations-t-dynamic">Nº of Bungalows</th>
                        {(section !== "Requests") ? "" : (<>
                            <th className="reservations-t-dynamic">Approve</th>
                            <th className="reservations-t-dynamic">Reject</th>
                            <th className="reservations-t-dynamic">Edit</th>
                        </>)}
                    </tr>

                    {dataFiltered.map((sub: TReservationData, idx: number) => (
                        <tr key={`sub${idx}`} className="reservations-data-tr">

                            <td> {sub.startDate} </td>
                            <td> {sub.endDate} </td>
                            <td className="reservations-t-email"> {sub.email} </td>
                            <td> {sub.guests} </td>
                            <td> {sub.oneDay? 0 : sub.bungalows} </td>
                            {(section !== "Requests") ? "" : (<>
                                <td><ApproveBtn src={Approved} alt="approve-reserve" onClick={(event: any) => { openInitialModal(sub.id, "ApproveReserve") }}/></td>
                                <td><RejectBtn src={BlackTrash} alt="reject-reserve" onClick={(event: any) => { openInitialModal(sub.id, "RejectReserve") }}/></td>
                                <td><EditBtn src={PenGray} alt="edit-reserve" onClick={() => { navigate(`/reservations/${sub.id}-item`) }} /></td>
                            </>)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </ReservationsTableSectionApp>
    );
}

const ReservationsTableSectionApp = styled.div`
   padding-top: 15px;
`

const ApproveBtn = styled.img`
    width: 20px;
    object-fit: cover;
    cursor: pointer;
`

const RejectBtn = styled.img`
    width: 11px;
    object-fit: cover;
    cursor: pointer;
`

const EditBtn = styled.img`
    width: 11px;
    object-fit: cover;
    cursor: pointer;
`