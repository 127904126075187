import React from "react";
import axios, { AxiosResponse } from 'axios';
import { app } from '../../config/config'
let BACKEND_URL = app.BACKEND_URL;

export interface TLocalReservation {
    id: string,
    startDate: string,
    endDate: string,
    email: string,
    guests: number,
    bungalows: number,
    oneDay?: boolean
}
const localData = {
    data: [
        {
            id: "0",
            startDate: "30/12/2022",
            endDate: "31/12/2022",
            email: "testing1@gmail.com",
            guests: 1,
            bungalows: 1,
            oneDay: false
        },
        {
            id: "1",
            startDate: "13/12/2022",
            endDate: "13/12/2022",
            email: "testing2@gmail.com",
            guests: 2,
            bungalows: 1,
            oneDay: true
        },
        {
            id: "2",
            startDate: "18/12/2022",
            endDate: "22/12/2022",
            email: "testing1@gmail.com",
            guests: 4,
            bungalows: 2,
            oneDay: false
        },
        {
            id: "3",
            startDate: "16/12/2022",
            endDate: "18/12/2022",
            email: "testing3@gmail.com",
            guests: 1,
            bungalows: 1,
            oneDay: false
        },
        {
            id: "4",
            startDate: "11/12/2022",
            endDate: "12/12/2022",
            email: "testing4@gmail.com",
            guests: 3,
            bungalows: 3,
            oneDay: false
        },
        {
            id: "5",
            startDate: "12/12/2022",
            endDate: "16/12/2022",
            email: "testing5@gmail.com",
            guests: 3,
            bungalows: 2,
            oneDay: false
        },
        {
            id: "6",
            startDate: "22/2/2023",
            endDate: "25/2/2023",
            email: "testing1@gmail.com",
            guests: 2,
            bungalows: 1,
            oneDay: false
        },
        {
            id: "7",
            startDate: "10/1/2023",
            endDate: "13/1/2023",
            email: "testing6@gmail.com",
            guests: 5,
            bungalows: 5,
            oneDay: false
        }
    ]
}


const formatDate = (date: Date) => {
    const day = date.getUTCDate();
    const month = +date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
}
/*interface WalletsState {
    id_item:string,
    start_date: string,
    end_date: string,
    email: string,
    guests: number,
    bungalows: number,
    state?: string
    oneDay?:boolean
}*/
interface WalletsState {
    number_guests: number,
    reservation_end: number,
    number_bungalows: number,
    id_item: string,
    reservation_start: number,
    user: string,
    reservation_type: string
    requested_at: number,
    email?: string
    status?: string
}
export async function reservationsRequest(fromDate: string, toDate: string) {

    let [sDay, sMonth, sYear] = fromDate.split("/");
    let [eDay, eMonth, eYear] = toDate.split("/");
    sMonth = +sMonth < 10? `0${sMonth}`: sMonth;
    sDay = +sDay < 10? `0${sDay}`: sDay;
    eMonth = +eMonth < 10? `0${eMonth}`: eMonth;
    eDay = +eDay < 10? `0${eDay}`: eDay;
    
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations?startDate=${sYear}-${sMonth}-${sDay}&endDate=${eYear}-${eMonth}-${eDay}`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<{ data: Array<WalletsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                //console.log(response)
                const data = response.data.items.map((element: WalletsState) => {
                    let startDate = formatDate(new Date(element.reservation_start));
                    let endDate = formatDate(new Date(element.reservation_end));
                    let oneDay = element.reservation_type === "nights" ? false : true
                    return {
                        id: element.id_item,
                        startDate,
                        endDate,
                        email: element.email,
                        guests: element.number_guests,
                        bungalows: element.number_bungalows,
                        oneDay,
                        status: element.status
                    }
                })
                resolve({ data })
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}

export async function localReservationsRequest() {
    return new Promise<{ data: Array<any> }>((resolve, rejects) => {
        resolve(localData)
    })
}



export async function getReservation(reservationId: any) {
    let axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations/${reservationId}`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<{ data: TLocalReservation }>((resolve, reject) => {
        axios(axiosOptions).then((response) => {
            let element: WalletsState = response.data;
            let startDate = formatDate(new Date(element.reservation_start));
            let endDate = formatDate(new Date(element.reservation_end));
            let oneDay = element.reservation_type === "nights" ? false : true
            let data: TLocalReservation = {
                id: element.id_item,
                startDate,
                endDate,
                email: element.email || "",
                guests: element.number_guests,
                bungalows: element.number_bungalows,
                oneDay
            }
            resolve({ data: data })
        }).catch((error) => {
            reject(error)
        })
    })
}

export async function getLocalReservation(reservationId: any) {
    return new Promise<{ data: TLocalReservation }>((resolve, rejects) => {
        let filteredReservation = localData.data.filter((element) => element.id === reservationId)[0];
        resolve({ data: filteredReservation })
    })
}



export async function approveReservation(reservationId: any) {
    let axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations/${reservationId}`,
        method: "POST",
        withCredentials: true,
        data: {
            "type": "setStatusReservation",
            "status": "confirmed"
        }
    }

    return new Promise<{ data: TLocalReservation }>((resolve, reject) => {
        axios(axiosOptions).then((response) => {
            //console.log(response)
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

export async function rejectReservation(reservationId: any) {
    let axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations/${reservationId}`,
        method: "POST",
        withCredentials: true,
        data: {
            "type": "setStatusReservation",
            "status": "canceled"
        }
    }

    return new Promise<{ data: TLocalReservation }>((resolve, reject) => {
        axios(axiosOptions).then((response) => {
            //console.log(response)
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}


export async function deleteReservation(reservationId: any) {
    let axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations/${reservationId}`,
        method: "DELETE",
        withCredentials: true
    }

    return new Promise<any>((resolve, reject) => {
        axios(axiosOptions).then((response) => {
            //console.log("Rejected successfully")
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}



export interface TbookingPost {
    "reservation_start": string,
    "reservation_end": string,
    "type": string,
    "guests": number,
    "bungalows": number
}
export async function editReservation({ reservationId, payload }: { reservationId: any, payload: TbookingPost }) {
    let axiosOptions = {
        url: `${BACKEND_URL}/api/admin/reservations/${reservationId}`,
        method: "PUT",
        withCredentials: true,
        data: payload
    }

    return new Promise<any>((resolve, reject) => {
        axios(axiosOptions).then((response) => {
            //console.log(response)
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}


