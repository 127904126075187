import { AnyAction } from "@reduxjs/toolkit";
import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import EventList from "../../components/eventsList/eventList";
import { getApiEvents, getApiEventsStatus, getApiEventsErrors, fetchEvents} from '../../services/events/eventsSlice'
import "./Events.css"
import PlusImage from '../../assets/icons/plus.png';
import { NavLink } from "react-router-dom";

interface widgetProps { identifier?: string }
export default function Events( props: widgetProps ) {
    const dispatch = useAppDispatch();
    const events = useSelector(getApiEvents);
    const eventsStatus = useSelector(getApiEventsStatus);
    const eventsErrors = useSelector(getApiEventsErrors);
    
    /*useEffect(()=>{ 
        console.log(eventsStatus)
        if(eventsStatus == 'idle'){ 
            dispatch(fetchEvents())
        }}, [eventsStatus, dispatch]);*/
    
    useEffect(()=>{dispatch(fetchEvents());}, [])

    return(
        <div className="eventApp">
            <div className="event-main">
                <div className="events-main__NewEvent">
                    <NavLink to="/events/new"  className="newEvent-button">
                        <h3 className="newEvent-buttonTitle">Add New Event </h3>
                        <img src={PlusImage} alt="plusButton" className="newEvent-btnImg" />
                    </NavLink>
                </div>

                <div className="events-main__ListEvent">
                    <EventList apiEvents={events}/>
                </div>
            </div>
        </div>
    );
}