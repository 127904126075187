import React, { useState } from 'react'
import styled from 'styled-components'
import { applicationDataType } from '../../../services/apiSlice/apiTypes'

import ModalNoIcon from '../../../components/modals/modalNoCloseIcon'
import ApplyModal from './applyModal'
import ApplicationApproved from './approveModal'
import ApplicationRejected from './rejectModal'
import ErrorRequestModal from './errorModal'

interface thisProps { 
  closeModal: () => void,
  cleanSelectedApply: () => void,
  paymentId: string
}

export type ApplicactionStepsTypes = 'data' | 'approve' | 'reject' | 'error'

export default function ModalApplication(props: thisProps) {
  const {closeModal, cleanSelectedApply, paymentId} = props
  const [ step, setStep ] = useState<ApplicactionStepsTypes>('data')
  const [ erroMsg, setErrorMsg ] = useState<string|undefined>(undefined)

  const changeStep = (step: ApplicactionStepsTypes) => setStep(step)
  const errorMessage = (msg: string) => setErrorMsg(msg)

  return(
    <ModalNoIcon
      closeModal={closeModal}
    > 
      <>
        { step === 'data' &&
          <ApplyModal
            paymentId={paymentId}
            changeStep={changeStep}
            cleanSelectedApply={cleanSelectedApply}
            closeModal={closeModal}
            errorMsg={errorMessage}
          />
        }

        {/* { step === 'approve' &&
          <ApplicationApproved
            closeModal={closeModal}
            applyData={selectedApply}
          />
        }

        { step === 'reject' &&
          <ApplicationRejected
            closeModal={closeModal}
            applyData={selectedApply}
          />
        }

        { step === 'error' && erroMsg &&
          <ErrorRequestModal
            closeModal={closeModal}
            applyData={selectedApply}
            errorMessage={erroMsg}
          />
        } */}
      </>
    </ModalNoIcon>
  )
}