import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate';
import './pagination.css'

import SearchViolet from '../../../../assets/icons/searchViolet.png'

interface propsType {
  page: number,
  limit: number,
  count: number | undefined,
  totalResults: number | undefined,
  status: string,
  searchText: string | undefined,
  isLoading: boolean,
  statusFilter: (status: string) => void,
  searchFilter: (searchText: string) => void,
  changePage: (page: number) => void,
  changeLimit: (limit: number) => void,
}

export default function Pagination(props: propsType) {
  const { page, limit, status, count, totalResults, searchText, isLoading } = props
  const [pageCount, setPageCount] = React.useState(0)

  useEffect(() => {
    if (totalResults && limit) {
      setPageCount(Math.ceil(totalResults / limit))
    }

  }, [limit, totalResults])

  function onPageChange(event: { selected: number }) {
    if (totalResults && limit) {
      props.changePage(event.selected + 1)
    }
  }

  function changeInput(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    const { target } = event

    if (target.name === 'applyNumberResults') {
      props.changeLimit(Number(target.value))
    }

    if (target.name === 'status-select') {
      props.statusFilter(target.value)
    }

    if (target.name === 'search-input') {
      props.searchFilter(target.value)
    }
  }


  return (
    <div>
      <ContentSearch>
        {/* <SearchInput type="text" placeholder='Search..' /> */}
        <SearchBarApp>
          <SearchInputText
            type="text"
            placeholder="Search"
            name="search-input"
            value={searchText ? searchText : '' }
            onChange={changeInput}
          />
          <SearchLogo src={SearchViolet} alt="search" />
        </SearchBarApp>
        <SelectStatus
          name="status-select"
          id="status-select"
          onChange={changeInput}
          value={status}
        >
          <option value="all">All</option>
          <option value="approved">Approved</option>
          <option value="pending">Pending</option>
          <option value="denied">Denied</option>
          <option value="whitelist">WhiteList</option>
          <option value="paymentProcessed">Paid</option>
        </SelectStatus>
      </ContentSearch>
      <Container>

        <Left>
          <PaginationInfo>
            { isLoading ? 'Loading' :
              (count && totalResults) ? 
              `${(page - 1) * limit} - ${(page - 1) * limit + count} of ${totalResults} results`
              :
              'No Results'
            }
          </PaginationInfo>
        </Left>

        <Rigth>
          <Label htmlFor='applyNumberResults' >
            # per Page:
          </Label>

          <Select
            name="applyNumberResults"
            id="applyNumberResults"
            onChange={changeInput}
            value={limit}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50" selected>50</option>
          </Select>

          <ReactPaginate
            previousLabel="PREVIOUS"
            nextLabel="NEXT"
            pageClassName="apply-page-item"
            pageLinkClassName="apply-page-link"
            //previousClassName="page-item"
            //previousLinkClassName="page-link"
            //nextClassName="page-item"
            //nextLinkClassName="page-link"
            breakLabel="..."
            //breakClassName="page-item"
            //breakLinkClassName="page-link"
            breakClassName="apply-nav-break"
            previousLinkClassName="apply-nav-previous"
            nextLinkClassName="apply-nav-next"
            nextClassName="apply-nav-button"
            previousClassName="apply-nav-button"

            pageCount={pageCount}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            containerClassName="apply-pagination"
            activeClassName="apply-active"
            activeLinkClassName="apply-link-active"
            forcePage={page - 1}
          />
        </Rigth>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 300px);
  justify-content: space-between;
`
const PaginationInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  margin-left: 50px;
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Rigth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`

const Select = styled.select`
  font-size: 18px;
  width: 80px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  border: 2px solid #830F5B;
  color: #830F5B;
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
`
const Label = styled.label`
  margin-right: 10px;
  color: #830F5B;
`

const SelectStatus = styled.select`
  box-sizing: border-box;   
  font-family: "trade-gothic";
  font-size: 14px;
  float: left;
  width: 250px;
  border: 1px solid #F0EEDD;
  border-radius: 13px;
  padding: 10px;
  padding-right: 30px;
  color: #777777;
  
`

const StatusSection = styled.div`
  /* margin-left: 50px; */
`

const ContentSearch = styled.div`
  display: flex;
  gap: 20px;
  padding: 5px 40px;
  flex-wrap: wrap;
`

const SearchInput = styled.input`
  font-size: 18px;
  width: 200px;
  float: left;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  border: 2px solid #830F5B;
  color: #830F5B;
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
`


const SearchBarApp = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 250px;
    height: 40px;
    display: flex;
    
`

const SearchInputText = styled.input`
    box-sizing: border-box;
    width: 250px;
    font-family: "trade-gothic";
    font-size: 14px;
    width: 250px;
    border: 1px solid #F0EEDD;
    border-radius: 13px;
    padding: 10px;
    padding-right: 30px;
    color: #777777;
`

const SearchLogo = styled.img`
    box-sizing: border-box;
    position: absolute;
    width: 15px;
    object-fit: contain;
    right: 10px;
    top: 32%;
`