import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { app } from "../../config/config"
import { applicationsResponseType, applicationActionResponseType, paymentsResponseType } from "./apiTypes";
import fetch, { Headers, Request, Response } from "cross-fetch"

const BACKEND_URL = app.BACKEND_URL;

global.fetch = fetch as any
global.Headers = Headers as any
global.Request = Request as any
global.Response = Response as any


export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    fetchFn: fetch,
  }),
  tagTypes: ["User", "Applys", "Payments"],
  endpoints: (builder) => ({

    // -----------  Applicactions memberships -----------

    getApplications: builder.query<applicationsResponseType, {
      page: string,
      limit: string,
      status?: string,
      search?: string | undefined,
    }>({
      query: (args) => {
        let url = `/api/admin/applications?page=${args.page}&limit=${args.limit}`

        if (args.status)
        url = `${url}&status=${args.status}`

        if (args.search && args.search.length > 2)
        url = `${url}&search=${args.search}`

        return {
          url,
          credentials: "include",
        }
      },
      providesTags: ["Applys"]
    }),

    approveApplication: builder.mutation<applicationActionResponseType, { applyId: string, type: 'approved' | 'whitelist' }>({
      query: (args) => ({
        url: `/api/applications/${args.applyId}`,
        method: 'PUT',
        credentials: 'include',
        body: {
          type: "applicationResponse",
          action: args.type
        }
      }),
      invalidatesTags: ['Applys']
    }),
    rejectApplication: builder.mutation<applicationActionResponseType, { applyId: string }>({
      query: (args) => ({
        url: `/api/applications/${args.applyId}`,
        method: 'PUT',
        credentials: 'include',
        body: {
          type: "applicationResponse",
          action: "denied"
        }
      }),
      invalidatesTags: ['Applys']
    }),



    getPayments: builder.query<paymentsResponseType, {
      page: string,
      limit: string,
      status?: string,
      search?: string | undefined,
    }>({
      query: (args) => {
        let url = `/api/admin/payments?page=${args.page}&limit=${args.limit}`

        if (args.status)
        url = `${url}&status=${args.status}`

        if (args.search && args.search.length > 2)
        url = `${url}&search=${args.search}`

        return {
          url,
          credentials: "include",
        }
      },
      providesTags: ["Payments"]
    }),

  })
})

export const {
  useGetApplicationsQuery,
  useRejectApplicationMutation,
  useApproveApplicationMutation,

  useGetPaymentsQuery,
} = apiSlice