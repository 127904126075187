import React, { useState } from "react";
import { ButtonsSection, CustomButton } from './stylingForm'

interface widgetProps { collectionData: any, type: string, handleSave: Function, handleDelete: Function, handleCancel: Function }
export default function ButtonsArea(props: widgetProps) {
    let { collectionData, type, handleSave, handleDelete, handleCancel } = props;
    return (
        <ButtonsSection type={props.type}>
            <CustomButton option={1} onClick={() => { handleSave() }} disabled={collectionData.Launched}>
                Save
            </CustomButton>

            {type !== "edit" ? '' : (
                <CustomButton option={2} onClick={() => { handleDelete() }} disabled={collectionData.Launched}>
                    Delete
                </CustomButton>
            )}

            <CustomButton option={2} onClick={() => { handleCancel() }}>
                Cancel
            </CustomButton>
        </ButtonsSection>
    );
}