import React, { useEffect, useState } from 'react'
import { useGetPaymentsQuery } from '../../services/apiSlice/apiSlice'
import { PaymentDataType } from '../../services/apiSlice/apiTypes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import './Payments.css'

import Pagination from './components/pagination/pagination'
import ModalApplicationEdition from '../../components/modals/modalApplicationEdition'
import ModalApplication from './modalFlow/Navigator'


export default function Payments() {
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ applys, setApplys ] = useState<PaymentDataType[]>([])
  const [ limit, setLimit ] = useState<number>(searchParams.get('limit') ? parseInt(searchParams.get('limit')!) : 50)
  const [ page, setPage ] = useState<number>(searchParams.get('page') ? parseInt(searchParams.get('page')!) : 1)
  const [ status, setStatus ] = useState<string>(searchParams.get('status') ? searchParams.get('status')! : "all")
  const [ search, setSearch ] = useState<string|undefined>(searchParams.get('search') ? searchParams.get('search')! : undefined)
  const [ totalResults, setTotalResults ] = useState<number|undefined>(undefined)
  const [ count, setCount ] = useState<number|undefined>(undefined)

  const [ showModal, setShowModal] = useState(false)
  const [selectedApply, setSelectedApply] = useState<string|undefined>(undefined);
  
  const { data: payments, isSuccess, isLoading, isFetching , error } = useGetPaymentsQuery({
    page: page.toString(),
    limit: limit.toString(),
    status: status,
    search: search ? search.length > 2 ? search : undefined : undefined
  })

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  function getDateNow (date_ac: number) {
    const d = new Date(date_ac);

    const date = d.getDate()
    const month = monthNames[d.getMonth()]
    const year = d.getFullYear()

    return `${month} ${date}, ${year}`;
  };

  useEffect(() => {
    if (isSuccess) {
      setApplys(payments.items)
      setTotalResults(payments.totalResults)
      setCount(payments.count)
    }
  }, [isSuccess, payments])


  const closeModal = () => setShowModal(false)
  const cleanSelectedApply = () => setSelectedApply(undefined)
  

  const changePage = (toPage: number) => {
    setPage(toPage)
    searchParams.set('page', toPage.toString())
    setSearchParams(searchParams)
  }
  const changeLimit = (toLimit: number) => {
    setLimit(toLimit)
    searchParams.set('limit', toLimit.toString())
    setSearchParams(searchParams)
  }
  const statusFilter = (toStatus: string) => {
    setStatus(toStatus)
    searchParams.set('status', toStatus)
    setSearchParams(searchParams)
  }

  const searchFilter = (searchText: string) => {
    if (searchText === '') {
      setSearch(undefined)
      searchParams.delete('search')  
      setSearchParams(searchParams)
    } else {      
      setSearch(searchText)
      searchParams.set('search', searchText)
      setSearchParams(searchParams)
    }
  }

  return (
    <div className="applications-main">

      <div className="applications-titleSection">
        <h1 className="applications-title">Memberships payments</h1>
      </div>

      <Pagination
        page={page}
        limit={limit}
        status={status}
        count={count}
        totalResults={totalResults}
        searchText={search}
        isLoading={isFetching || isLoading}
        statusFilter={statusFilter}
        searchFilter={searchFilter}
        changePage={changePage}
        changeLimit={changeLimit}
      />

      <div className='applications-subscribers-table'>
        <table>
          <tbody>
            <tr>
              <th className='application-leftPad'>User</th>
              <th className='application-leftPad'>Membership Type</th>
              <th className='application-leftPad'>Payment</th>
              <th className='application-leftPad'>Installments</th>
              <th className='application-leftPad'>Arrears</th>
              {/* <th className='application-leftPad'>Name</th> */}
              {/* <th className='application-leftPad'>Email</th> */}
              {/* <th className='application-leftPad'>Phone Number</th> */}
              {/* <th className='application-leftPad'>Twitter</th> */}
              {/* <th className='application-leftPad'>Instagram</th> */}
              {/* <th className='application-leftPad'>LinkedIn</th> */}
              {/* <th className='application-leftPad'>General Location</th> */}
              {/* <th className='application-leftPad'>Intended Uses</th> */}
              {/* <th className='application-leftPad'>Intended Uses Penthouse</th> */}
              {/* <th className='application-leftPad'>Intended Uses Events</th> */}
              {/* <th className='application-leftPad'>Intended Uses Networking</th> */}
              {/* <th className='application-leftPad'>Intended Uses Other</th> */}
              
              {/* <th className='application-leftPad'>Referal Code</th> */}
              {/* <th className='application-leftPad'>Wallet</th> */}
              {/* <th className='application-leftPad'>Terms And Conditions</th> */}
              {/* <th className='application-leftPad'>White List</th> */}
              {/* <th className='application-leftPad'>Notes</th> */}
              {/* <th className='application-leftPad'>Status</th>
              <th className='application-leftPad'>Application Date</th> */}
              {/* <th className='application-leftPad'>Has Gone Through Onboarding</th> */}
            </tr>

            { applys && applys.map((sub) => (
              <tr
                className='row-list'
                key={`sub${sub.id_item}`}
                onClick={() => { 
                  //openEditModal(sub)
                  setSelectedApply(sub.id_item)
                  setShowModal(true)
                }}
                >
                <td className="application-leftPad application-applicationId"> {sub.id_item} </td>
                <td className="application-leftPad application-applicationName"> {sub.membership_type} </td>
                <td className="application-leftPad application-applicationName"> {sub.type} </td>
                <td className="application-leftPad application-applicationName">
                  { sub.type === 'installments' ? sub.payments_status.filter(x => x===true).length + ' / 12' :  null }
                </td>
                <td className="application-leftPad application-applicationName">
                  { sub.type === 'installments' && sub.arrears? sub.arrears :  null }
                </td>
                {/* <td className="application-leftPad application-applicationName"> {sub.firstName} {sub.lastName} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.email} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.phone} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.twitter} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.instagram} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.linkedIn} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.generalLocation} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.intendedUsesVineyard} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.intendedUsesPenthouse} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.intendedUsesEvents} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.intendedUsesNetworking} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.intendedUsesOther} </td> */}
                
                {/* <td className="application-leftPad application-applicationName"> {sub.referalCode} </td> */}
                {/* <td className="application-leftPad application-applicationName">
                  { `${sub.wallet.substring(0, 8)}...` }
                </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.termsAndConditions} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.whiteList ? 'Yes' : ''} </td> */}
                {/* <td className="application-leftPad application-applicationName">
                  { `${sub.notes.substring(0, 20)}...` }
                </td> */}
                {/* <td className="application-leftPad application-applicationName">
                  { sub.checkout && sub.checkout === 'success' ? 'checkout success' : sub.status}
                </td>
                <td className="application-leftPad application-applicationName"> {getDateNow(sub.created_at)} </td> */}
                {/* <td className="application-leftPad application-applicationName"> {sub.hasGoneThroughOnboarding} </td>    */}             
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      { showModal && selectedApply &&
        <ModalApplication
          closeModal={closeModal}
          cleanSelectedApply={cleanSelectedApply}
          paymentId={selectedApply}
        />

      }

      {/* { editAppModalState &&
        <ModalApplicationEdition {...userEditionParams} />
      } */}

    </div>
  );
}
