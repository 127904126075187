import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import Backdrop from "./backdrop";
import ReactDOM from 'react-dom'
import CloseImage from '../../assets/icons/xclose.png'
import UserForm from "../forms/userForm";

export interface TModalUserEdition {
    id: number|undefined,
    userData: any,
    updateUserData: Function,
    closeModal: MouseEventHandler
}

export default function ModalUserEdition(props: TModalUserEdition) {
    const {id, userData, updateUserData, closeModal} = props;
    const [editableUserData, setEditableUserData] = useState({...userData});

    const confirmModal = () =>{
        updateUserData(id, editableUserData)
    }
    const rootBackDrop = document.getElementById('modals');
    return (
        <React.Fragment>
            <Backdrop onclick={closeModal}/>
            {ReactDOM.createPortal(
                <ModalContainer>
                    <BoxMain>
                        <Title>EDIT USER</Title>
                        <UserForm userData={editableUserData} updateUserData={(data:any)=>{setEditableUserData(data)}}/>
                        <ButtonArea>
                            <AcceptButton onClick={confirmModal}>ACCEPT</AcceptButton>
                            <CancelButton onClick={closeModal}>CANCEL</CancelButton>
                        </ButtonArea>
                    </BoxMain>
                </ModalContainer>,
            rootBackDrop as Element)}
        </React.Fragment>
    );
}

const ModalContainer = styled.div`
    position: fixed;    
    z-index: 1001;
    width: 500px;
    left: calc(100vw/2 - 290px);
    top: calc(100vh/2 - 320px)
`
const BoxMain = styled.div`
    border-radius: 10px;
    background: #F0EEDD;
    text-align: center;
    font-family: 'trade-gothic';
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
`
const Title = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
    margin: 5px;
    padding: 10px;
`
const Message = styled.p`
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    color: #979696;
    margin: 15px 0px;
`

const ButtonArea = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 10px;
`
    const AcceptButton = styled.button`
        box-sizing: border-box;
        background: #84004C;
        border: 1px solid #84004C;
        color: #FFFFFF;
        border-radius: 10px;
        padding: 12px 20px;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
    `

    const CancelButton = styled.button`
    box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #84004C;
        color: #84004C;
        border-radius: 10px;
        padding: 12px 20px;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
    `

const CloseButton = styled.img`
    position: absolute;
    width: 10px;
    height: 10px;
    object-fit: cover;
    top: 15px;
    right: 15px;
    cursor: pointer;
`