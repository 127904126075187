import { createAsyncThunk, createImmutableStateInvariantMiddleware, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { requesUserInfo } from './userInfoReq';

import Cookies from 'universal-cookie';
import { stat } from 'fs/promises';

const cookies = new Cookies();

export interface userData {
  sub: string,
  role: string,
  email: string,
  profileImage: string|undefined,
  username: string,
  channels: string[],
}

export interface UserState {
  status: string,
  data: userData|undefined
}

const initialState: UserState = {
  status: 'initial',
  data: undefined
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const requestUserInfo = createAsyncThunk(
  'user/requestUserInfo',
  // async (token: string) => {
  async () => {
    const response = await requesUserInfo();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUserInfo: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestUserInfo.pending, (state) => {
        // console.log('loading');
        state.status = 'loading';
      })
      .addCase(requestUserInfo.fulfilled, (state, action) => {        
        // console.log('logged');
        state.status = 'logged';
        state.data = action.payload;

        cookies.set('vrc_admin', action.payload.username );
      })
      .addCase(requestUserInfo.rejected, (state) => {
        // console.log('failed');
        state.status = 'failed';

        cookies.remove('vrc_admin');
      });
  },
});

export const { setUserInfo } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserInfo = (state: RootState) => state.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userSlice.reducer;
