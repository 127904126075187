import React from "react";
import PurpleMountain from '../../assets/icons/purpleMountain.png';
import PenGray from '../../assets/icons/penGray.png';
import { SectionForm, SectionTitle, InstructionText, BannerImageContainer, PurpleDot, ImageInputType, EditButton, EmptyImageContainer, BannerImage } from './stylingForm'
import {onFileChange} from '../../services/collections/imageToFile'

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setImagesInfo, selectImagesInfo} from '../../services/collections/imageCollectionSlice';

interface widgetProps { collectionData: any, updateCollectionAttributes:Function }

export default function Banner(props: widgetProps) {
    let dispatch = useAppDispatch();
    let imagesCollectionContext = useAppSelector(selectImagesInfo);

    let {collectionData, updateCollectionAttributes} = props;

    let updateBannerImage = (event: any) => { 
        updateCollectionAttributes("BannerImage", URL.createObjectURL(event.target.files[0])) 
            
        onFileChange(event).then((response:any) => {
            let temporalContext:any = {...imagesCollectionContext.data}
            temporalContext.bannerImage = response[0].file;
            dispatch(setImagesInfo(temporalContext))
        }).catch((error) => {
            console.log("error: " + error)
        })
    }
    let clickBanner = () => { document.getElementById("BannerImageFile")?.click() }
    let normalMountain = (id: string) => {
        return (
            <EmptyImageContainer>
                <img src={PurpleMountain} alt="EmptyImage" width="60px" height="60px" onClick={clickBanner} />
            </EmptyImageContainer>
        )
    }

    let bannerImage = <BannerImage src={collectionData.BannerImage} alt="BannerImage" />;
    return (
        <SectionForm>
            <SectionTitle>Banner image <PurpleDot /></SectionTitle>
            <InstructionText> This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensionschange on different devices. 1400 x 350 recommended.</InstructionText>
            <BannerImageContainer>
                {collectionData.BannerImage ? bannerImage : normalMountain("BannerImageFile")}
                {!collectionData.BannerImage ? "" : (
                    <EditButton onClick={clickBanner} disabled={false/*collectionData.Launched*/} state={false/*collectionData.Launched*/}>
                        Edit banner image
                        <img src={PenGray} alt="edit" />
                    </EditButton>
                )}
                <ImageInputType type="file" id="BannerImageFile" name="BannerImageFile" onChange={updateBannerImage} />
            </BannerImageContainer>
        </SectionForm>
    );
}