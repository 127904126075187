import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import Checked from '../../assets/icons/checked.png';
import XClose from '../../assets/icons/xclose.png'
import DownArrowPic from '../../assets/icons/downArrow.png'

interface optionsInput {
    name: string,
    active: boolean
}

interface widgetProps {
    selected: Array<string> | string,
    type: string,
    options: Array<optionsInput>,
    updateSelected: Function,
    dropFlag: boolean,
    updateDropFlag: Function,
    customDisabled: boolean
}

interface optionState {
    name: string,
    state: string
    id: number
}



export default function CustomDropMenu(props: widgetProps) {
    let [optionsState, setOptionsState] = useState<optionState[]>([]);
    let [activeOptions, setActiveOptions] = useState<optionState[]>([]);

    useEffect(() => {
        let newListState = props.options.map((element, index) => {
            let isArray = Array.isArray(props.selected);
            let newElement: optionState = {
                id: index,
                name: element.name,
                state: "normal"
            }
            
            if ((isArray && props.selected.includes(element.name)) || (props.selected === element.name)) newElement.state = "active";
            if (!element.active) newElement.state = "disabled";

            return newElement;
        })
        setOptionsState(newListState)
        setActiveOptions(newListState.filter(x => x.state === "active"))
    }, [props.selected, props.options])


    let updateState = (event: any) => {
        if (!props.customDisabled) {
            let indexOption = event.target.classList.value.match(/(?<=selection-)\d+/)[0];
            let newListState = optionsState;

            let selectedOption = newListState[indexOption]
            newListState[indexOption] = selectedOption.state === "normal" ? { ...selectedOption, state: "active" } : { ...selectedOption, state: "normal" }

            if (props.type === "simple") {
                newListState = newListState.map(x => (x.id !== +indexOption) ? { ...x, state: "normal" } : { ...x })
            }

            let currentSelectedOptions:string|Array<string> =  newListState.filter(x => x.state === "active").map(x => x.name);
            if(props.type === 'simple'){
                currentSelectedOptions = currentSelectedOptions[0] || '';
            }

            setOptionsState(newListState)
            setActiveOptions(newListState.filter(x => x.state === "active"))
            props.updateSelected(currentSelectedOptions)
        }
    }

    let removeSelection = (event: any) => {
        if (!props.customDisabled) {
            const indexOption = event.target.classList.value.match(/(?<=option-)\d+/)[0];
            const newListState = optionsState;
            newListState[indexOption].state = 'normal';

            let currentSelectedOptions:string|Array<string> =  newListState.filter(x => x.state === "active").map(x => x.name);
            if(props.type === 'simple'){
                currentSelectedOptions = currentSelectedOptions[0] || '';
            }
            
            setOptionsState(newListState)
            setActiveOptions(newListState.filter(x => x.state === "active"))
            props.updateSelected(currentSelectedOptions)
        }
    }

    let chancheDropState = (event: any) => {
        if (!props.customDisabled) {
            let clickedCurrentInputOptions = event.target.classList.value.match(/(?<=option-)\d+/);
            if (!clickedCurrentInputOptions) {
                props.updateDropFlag()
            }
        }
    }


    return (
        <CustomDropMenuBox className="customDropMenu">
            <MainInputBox onClick={chancheDropState} state={props.customDisabled}>
                {!activeOptions.length ? <div /> : (
                    activeOptions.length === 1 ?
                        <SingleText>{activeOptions[0].name}</SingleText> : (

                            <TextOptions>
                                {activeOptions.map((y, index) => <TextOptionSelected className={`option-${y.id}`} key={y.name + index} onClick={removeSelection}>{y.name} <XButton src={XClose} className={`option-${y.id}`} /></TextOptionSelected>)}
                            </TextOptions>
                        )
                )}
                <DownArrow src={DownArrowPic} alt="down-arrow" />
            </MainInputBox>

            <SelectionBox dropState={props.dropFlag}>
                <EmptyBox state={props.customDisabled} />
                {optionsState.map((element, index) => {
                    let selectionElement = (
                        <SelectionOption onClick={updateState} key={element.name + "-" + index} state={element.state} className={`${element.state}selection-${index}`}>
                            <CheckBox state={element.state} className={`selection-${index}`}> {element.state === "active" ? <img src={Checked} className={`selection-${index}`} alt={`selection-${index}`}/> : ""}</CheckBox>
                            <SelectionText state={element.state} className={`selection-${index}`}>{element.name}</SelectionText>

                        </SelectionOption>
                    )
                    if (element.state === "disabled") {
                        selectionElement = (
                            <SelectionOption key={element.name + "-" + index} state="disabled">
                                <CheckBox state="disabled" />
                                <SelectionText state={element.state}>{element.name}</SelectionText>
                            </SelectionOption>
                        )
                    }
                    return selectionElement;
                })}
            </SelectionBox>
        </CustomDropMenuBox >
    );
}



const CustomDropMenuBox = styled.div`
    box-sizing: border-box;
    //width: 270px;
    width: 100%;
    position: relative;
`

const MainInputBox = styled.div<{state:boolean}>`
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    cursor: ${({state})=>state? "default": "pointer"};
    position: relative;
    z-index: 1;
`

const SingleText = styled.div`
    font-family: 'montserrat-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #4F4F4F;
    position: relative;
    z-index: 100;
    text-transform: capitalize;
`

const TextOptions = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    z-index: 10;
    text-transform: capitalize;
`

const TextOptionSelected = styled.div`
    font-family: 'montserrat-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #4F4F4F;
    background: #EDEDED;
    border-radius: 5.25183px;
    padding: 3px 8px;
    text-transform: capitalize;
`

const XButton = styled.img`
    object-fit: cover;
    width: 10px;
`

const DownArrow = styled.img`
    width: 10px;
    padding-right: 10px;
`

const SelectionBox = styled.div<{ dropState: boolean }>`
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    width: 100%;
    //width: 270px;
    display: ${({ dropState }) => dropState ? "flex" : "none"};
    background: linear-gradient(to bottom ,#FFFFFF00 0% 30px, #FFFFFF 10% 100%);
    z-index: 1;
    border-left: 1px solid #9B9B9B;
    border-right: 1px solid #9B9B9B;
    border-bottom: 1px solid #9B9B9B;
    border-collapse: collapse;
    border-radius: 0px 0px 10px 10px;
    user-select: none;
    
    flex-direction: column;
    padding: 0px 10px;
    z-index: 2;
`

const SelectionOption = styled.div<{ state: string }>`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: ${({ state }) => state === "disabled" ? 'default' : "pointer"};
    height: 25px;
    //background-color: #FFFFFF;
    border-bottom: 1px solid #EDEDED;
    position: relative;
    width: 100%;
    padding: 25px 5px;
    //margin: 0px 10px;

    &:hover{
        background-color: ${({ state }) => state === "disabled" ? '' : "#ffebfa"} ;
    }
`
const CheckBox = styled.div<{ state: string }>`
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 5px;
    border: ${({ state }) => state === "disabled" ? "1px solid #9B9B9B" : "1px solid #830F5B"};
    display: flex;
    justify-content: center;
    align-items: center;
    background:  ${({ state }) => state === "active" ? "#830F5B" : ""}; 
    padding: 3px;

    & img{
        object-fit: contain;
        width: 100%;
    }
`
const SelectionText = styled.div<{ state: string }>`
    font-family: 'montserrat-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${({ state }) => state === "disabled" ? "#9B9B9B" : "#4F4F4F"};
    text-transform: capitalize;
`

const EmptyBox = styled.div<{state:boolean}>`
    position: relative;
    height: 25px;
    opacity: 0.1;
    z-index: -1;
    cursor: ${({state})=>state? "default": "pointer"};
`