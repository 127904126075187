import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import Backdrop from "./backdrop";
import ReactDOM from 'react-dom'
import CloseImage from '../../assets/icons/xclose.png'

interface BackdropType {
    closeModal: MouseEventHandler,
    confirmModal?: MouseEventHandler,
    title?: string,
    message?: string,
    acceptText?: string,
    cancelText?: string
}

export default function ModalConfirmation(props: BackdropType) {
    const {title, message, acceptText, cancelText, closeModal, confirmModal} = props;
    const haveButtons:boolean = (acceptText && acceptText.length) || (cancelText && cancelText.length)? true : false;

    const rootBackDrop = document.getElementById('modals');
    return (
        <React.Fragment>
            <Backdrop onclick={props.closeModal}/>
            {ReactDOM.createPortal(
                <ModalContainer>
                    <BoxMain>
                        {title && <Title>{title}</Title>}
                        {message && <Message>{message}</Message>}
                        {haveButtons && (
                        <ButtonArea>
                            {acceptText && <AcceptButton onClick={confirmModal}>{acceptText}</AcceptButton>}
                            {cancelText && <CancelButton onClick={closeModal}>{cancelText}</CancelButton>}
                        </ButtonArea>)}
                    </BoxMain>
                </ModalContainer>,
            rootBackDrop as Element)}
        </React.Fragment>
    );
}

const ModalContainer = styled.div`
    position: fixed;    
    z-index: 1001;
    width: 420px;
    left: calc(100vw/2 - 220px);
    top: calc(100vh/2 - 140px)
`
const BoxMain = styled.div`
    border-radius: 10px;
    background: #F0EEDD;
    text-align: center;
    font-family: 'trade-gothic';
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
`
const Title = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
    margin: 0;
`
const Message = styled.p`
    font-style: normal;
    font-size: 20px;
    line-height: 25px;
    color: #979696;
    margin: 15px 0px 20px 0px;
`

const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
`
    const AcceptButton = styled.button`
        box-sizing: border-box;
        background: #84004C;
        border: 1px solid #84004C;
        color: #FFFFFF;
        border-radius: 10px;
        padding: 12px 20px;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
    `

    const CancelButton = styled.button`
    box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #84004C;
        color: #84004C;
        border-radius: 10px;
        padding: 12px 20px;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
    `

const CloseButton = styled.img`
    position: absolute;
    width: 10px;
    height: 10px;
    object-fit: cover;
    top: 15px;
    right: 15px;
    cursor: pointer;
`