import React, { useEffect, useState } from "react";
import "./collectionList.css"
import CollectionCard from "./collectionCard"
import { getCollectionsList, TCollectionsList, TShortCollection } from '../../services/collections/collectionsList'


export default function CollectionList() {

    let [dynamicCollection, setDynamicCollection] = useState<TCollectionsList>();

    useEffect(() => {
        //let listOfCollections:TCollectionsList = getLocalCollectionsList();
        //setDynamicCollection(listOfCollections)

        getCollectionsList().then((response) => {
            let newCollection: any = response?.data.items.map((element:any) => {
                let cardImage = Array.isArray(element["additional_image"])? element["additional_image"][0]: element["additional_image"]
                return ({
                    id: element["id_item"],
                    name: element["collection_name"],
                    price: element["price"],
                    status: element["status"],
                    image: cardImage
                })
            })
            setDynamicCollection({collections:newCollection});
        });
        
    }, [])

    let listSelectors = dynamicCollection && dynamicCollection.collections? dynamicCollection.collections.map((cardInfo: TShortCollection, index) => {
        return (
            <div className="collectionList-card" key={String(index)}>
                <CollectionCard cardInfo={cardInfo} />
            </div>
        )
    }) : '';

    return (
        <div className="collectionList">
            {listSelectors}
        </div>
    );
}