import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Calendar3 from "../../components/calendaries/Calendar3"
import { monthArr } from "../../components/calendaries/Calendar3";

import LeftArrow from '../../assets/icons/leftArrow.png';
import RightArrow from '../../assets/icons/rightArrow.png'

let initialCalendaryResult = {
    StartDate: undefined,
    EndDate: undefined,
    OneDate: undefined,
    BetweenDays: undefined
}

interface TCalendarResult {
    StartDate: string | undefined,
    EndDate: string | undefined,
    OneDate: string | undefined,
    BetweenDays: Array<any> | undefined
}

interface TCalendar {
    inputDate: string,
    mode: "RangeDays" | "OneDayPick",
    initialStatesForDates: any,
    resultState: TCalendarResult
    setResultState: Function
}

interface TWidgetProts {
    inputValue: string,
    setInputValue: Function,
    isDisabled: boolean,
    placeHolder?: string,
    modalCalendarFlag: boolean,
    setModalCalendarFlag: Function
}

let fixDateToDisplay = (date: string) => {
    let [day, month, year] = date.split("/")
    day = +day < 10 ? `0${+day}` : day;
    month = +month < 10 ? `0${+month}` : month;
    return (`${day}/${month}/${year}`)
}
let formatToLocal = (date: string) => {
    let [year, month, day] = date.split("-");

    return `${+day}/${+month}/${+year}`;
}
let formatToApi = (date: string) => {
    let [day, month, year] = date.split("/");
    day = +day < 10 ? `0${+day}` : day;
    month = +month < 10 ? `0${+month}` : month;
    return `${year}-${month}-${day}`;
}
export default function CalendarInputBox(props: TWidgetProts) {
    const { inputValue, setInputValue, isDisabled, placeHolder, modalCalendarFlag, setModalCalendarFlag } = props
    let todayDate = new Date();
    let fixedTodayDate = `${+todayDate.getUTCDate()}/${todayDate.getUTCMonth() + 1}/${todayDate.getFullYear()}`

    let [calendaryResult, setCalendaryResult] = useState<TCalendarResult>(initialCalendaryResult);
    let [dynamicDate, setDynamicDate] = useState(fixedTodayDate)

    useEffect(() => {
        if (inputValue) {

            let newCalendarState: TCalendarResult = {
                StartDate: undefined,
                EndDate: undefined,
                OneDate: formatToLocal(inputValue),
                BetweenDays: undefined
            }
            setCalendaryResult(newCalendarState);
            setDynamicDate(formatToLocal(inputValue))
        }
    }, [inputValue])

    const clickedLeft = () => {
        const [day, month, year] = dynamicDate.split("/");
        if ((+year === 0 || +year === 1) && +month === 1) return `31/12/9999`
        if (+month === 1) return `1/12/${+year - 1}`;
        setDynamicDate(`1/${+month - 1}/${year}`);
    }
    const clickedRight = () => {
        const [day, month, year] = dynamicDate.split("/");
        if (+year === 9999 && +month === 12) return `1/1/0001`
        if (+month === 12) return `1/1/${+year + 1}`;
        setDynamicDate(`1/${+month + 1}/${year}`);
    }
    const clickedCalendarInputBox = (event: any) => {
        if (!isDisabled) {
            if (event.target.className.includes("event-calendar-InputBox")) {
                setModalCalendarFlag()
                if (inputValue) setDynamicDate(formatToLocal(inputValue)) // to display current date always when modal is open
                else setDynamicDate(fixedTodayDate)
            }
        }
    }

    let calendarProps: TCalendar = {
        inputDate: dynamicDate,
        mode: "OneDayPick",
        initialStatesForDates: undefined,
        resultState: calendaryResult,
        setResultState: (result: any) => {
            setInputValue(formatToApi(result.OneDate))
        }
    }
    let displayedDate = calendaryResult.OneDate ? fixDateToDisplay(calendaryResult.OneDate) : placeHolder || "dd/mm/aaaa";

    return (
        <CalendarInputBoxApp >
            {modalCalendarFlag && <CalendarModal className="event-calendar-modal">
                <NavCalendar>
                    <Arrow onClick={clickedLeft} src={LeftArrow} />
                    <MonthDisplay>{monthArr[+dynamicDate.split("/")[1] - 1]}</MonthDisplay>
                    <Arrow onClick={clickedRight} src={RightArrow} />
                </NavCalendar>
                <Calendar3 {...calendarProps} />
            </CalendarModal>}
            <TextContent onClick={clickedCalendarInputBox} className="event-calendar-InputBox" isDisabled={isDisabled}>{displayedDate}</TextContent>
        </CalendarInputBoxApp>
    );
}


const CalendarInputBoxApp = styled.div`
    position: relative;
    box-sizing: border-box;
    border: 1px solid #9B9B9B;
    border-radius: 10px;
    width: 100%;
    font-family: 'montserrat-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #4F4F4F;
 
`

const CalendarModal = styled.div`
    position: absolute;
    bottom: 102%;
    left: 0%;
    background: #FFFFFF;
    padding: 5px;
    border: 1px solid #b1b1b1;
    border-radius: 2px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    z-index: 1;
`

const NavCalendar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
`

const Arrow = styled.img`
    height: 16px;
    cursor: pointer;
`

const MonthDisplay = styled.h3`
    margin: auto;
    font-size: 18px;
    font-family: 'trade-gothic';
`

const TextContent = styled.p<{ isDisabled: boolean }>`
    margin: 0;
    padding: 12px 15px;
    cursor: ${({ isDisabled }) => isDisabled ? "default" : "pointer"};
    width: 100%;
    box-sizing: border-box;
`