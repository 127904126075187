import React, { useState } from "react";
import CustomDropMenu from '../eventsList/customDropMenu'
import { SectionForm, SectionTitle, TwoSectionSplit, PurpleDot, TextInputArea } from './eventStylingForm'
import {TEventData} from '../eventsList/eventForm'
import Calendar3 from "../../components/calendaries/Calendar3"
import CalendarInputBox from "../eventFormElements/calendarInputBox";


interface widgetProps { eventData: TEventData, updateEventAttributes: Function, typeFlag: boolean, updateTypeFlag: Function, dropOptions:any, modalCalendarFlag: boolean, updateModalCalendarFlag:Function}
export default function DateAndType(props: widgetProps) {
    let { eventData, updateEventAttributes, typeFlag, updateTypeFlag, dropOptions, modalCalendarFlag, updateModalCalendarFlag } = props;
    const changeEventDate = (value: any) => {updateEventAttributes("Date", value)}
    const changeEventType = (value: any) => {updateEventAttributes("Type", value)}


    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>Date <PurpleDot /></SectionTitle>
                <CalendarInputBox inputValue={eventData.Date} setInputValue={changeEventDate} isDisabled={eventData.Status} modalCalendarFlag={modalCalendarFlag} setModalCalendarFlag={updateModalCalendarFlag}/>
            </SectionForm>
            <SectionForm>
                <SectionTitle>Type <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={eventData.Type} options={dropOptions.type} type="simple" updateSelected={changeEventType} dropFlag={typeFlag} updateDropFlag={updateTypeFlag} customDisabled={eventData.Status} />
            </SectionForm>
            
        </TwoSectionSplit>
    );
}