import React, {useState} from "react";
import { SectionForm, SectionTitle, DescriptionText, PurpleDot } from './eventStylingForm'

interface widgetProps { eventData: any, updateEventAttributes: Function }
export default function EventDescription( props: widgetProps ) {
    let { eventData, updateEventAttributes } = props;
    let changeDescription = (event: any) => { updateEventAttributes("Description", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Description <PurpleDot /></SectionTitle>
                <DescriptionText value={eventData.Description} onChange={changeDescription} rows={10} disabled={eventData.Status} />
            </SectionForm>
    );
}