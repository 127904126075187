import React, {useState, useContext, useEffect} from "react";
import styled from "styled-components";
import SearchBar from "../general/SearchBar";
import SearchDate from "../general/SearchDate";
import FilterDateContex from "../../context/filterDateContext";

interface TProps{ modalCalendarFlag: boolean, setModalCalendarFlag: Function, datesFilters:any, setDatesFilters:Function, searchBarText:string, setSearchBarText:Function }
export default function FilterSection(props: TProps) {
    const {modalCalendarFlag, setModalCalendarFlag, datesFilters, setDatesFilters, searchBarText, setSearchBarText} = props;
    const dateFilterContext = useContext(FilterDateContex);
    const {StartDate, EndDate} = dateFilterContext.Data;
    
    useEffect(()=>{

        if(StartDate.length && EndDate.length){            
            setDatesFilters({
                startDate: StartDate,
                endDate: EndDate 
            })
        }
    }, [dateFilterContext.Data])

    return(
        <FilterSectionApp>
            <SearchBar searchBarText={searchBarText} setSearchBarText={setSearchBarText}/>
            <SearchDate  modalCalendarFlag={modalCalendarFlag} setModalCalendarFlag={(value:boolean) => {setModalCalendarFlag(value)}}/>
        </FilterSectionApp>
    );
}


const FilterSectionApp = styled.div`
    display: flex;
    gap: 20px;
    padding: 15px 40px;
    flex-wrap: wrap;
`