import React, { useState } from "react";
import styled from 'styled-components'

interface widgetProps { keyId: any, name: string, type: string, enabled: boolean, data: any, updateData: Function }
export default function EditableField(props: widgetProps) {

    let onChangeHandler = (event: any) => {
        props.updateData(event.target.value, props.keyId)
    }

    let inputSelector = props.name && props.name.includes("Description") ? (
        <textarea value={props.data} onChange={onChangeHandler} rows={4}>
            {props.data}
        </textarea>) : (<input type={props.type} value={props.data} onChange={onChangeHandler} />);

    let customInput = props.enabled ? inputSelector : (
        <StaticText>{props.data}</StaticText>
    );


    return (
        <EditableFieldApp >
            <Title>{props.name}:</Title>{customInput}
        </EditableFieldApp>
    );
}

const EditableFieldApp = styled.div`
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    font-family: 'trade-gothic';
    font-size: 14px;
    align-items: flex-start;
    justify-content: space-between;

    input{
        display: inline;
        width: 100%;
        height: 15px;
        font-size: 14px;
        font-family: 'trade-gothic';
        font-weight: 0;
        color: #4F4F4F
        
    };

    textarea{
        display: inline;
        width: 100%;
        height: 70px;
        font-size: 14px;
        font-family: 'trade-gothic';
        font-weight: 0;
        color: #4F4F4F;

        ::-webkit-scrollbar{
        display: none;
        }
    };


    p{
        display: inline;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: #4F4F4F
    }
`

const Title = styled.div`
    display: inline;
    font-weight: bold;
`

const StaticText = styled.p`
    color: #4f4f4f;
    font-size: 14px;
    max-width: 130px;
`