import React, { useState } from "react";
import "./WalletCard.css"
import BlackEdit from "../../assets/icons/penBlack.png"
import { NavLink } from "react-router-dom";
import { EmptyImageContainer } from '../walletFormElements/walletStylingForm';
import PurpleWallet from '../../assets/icons/purpleWallet.png';

interface TWalletCard {
    cardInfo: {
        id: string,
        WalletName: string,
        WalletImage: string,
        Category: string
        WalletAddress: string
    }
}
export default function WalletCard(props: TWalletCard) {
    const { id, WalletName, WalletImage, Category, WalletAddress } = props.cardInfo;
    let normalMountain = (
        <EmptyImageContainer clickable={false}>
            <img src={PurpleWallet} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )

    let walletImageSource = <img src={WalletImage} alt={WalletName} className="cardImage" />
    return (
        <div className="walletCard">
            <div className="walletCard-Image">
                    {WalletImage ? walletImageSource : normalMountain}
                    <NavLink to={`/wallets/${id}-item`} className="cardEdit">
                        <img src={BlackEdit} alt="editButton" className="cardEdit-image" />
                    </NavLink>
            </div>
            <div className="walletCard-Info">
                <p>{WalletName}</p>
                <p>Category: {Category}</p>
                <p>Address: {WalletAddress.length>19? WalletAddress.slice(0,19) + "...": WalletAddress}</p>
            </div>
        </div>
    );
}