import React, {useState} from "react";
import { SectionForm, SectionTitle, PurpleDot, TextInputArea } from './stylingForm'

interface widgetProps { collectionData: any, updateCollectionAttributes: Function }
export default function CollectionName( props: widgetProps ) {
    let { collectionData, updateCollectionAttributes } = props;
    let changeDescription = (event: any) => { updateCollectionAttributes("Description", event.target.value) }
    let changeCollectionName = (event: any) => { updateCollectionAttributes("CollectionName", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Collection Name <PurpleDot /></SectionTitle>
                <TextInputArea type="text" value={collectionData.CollectionName} onChange={changeCollectionName} withLogo={false} disabled={collectionData.Launched} />
            </SectionForm>
    );
}