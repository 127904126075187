import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"
import { setupListeners } from '@reduxjs/toolkit/query' 

import counterReducer from '../services/counter/counterSlice';
import userReducer from '../services/user/userSlice';
import imagesCollectionReducer from '../services/collections/imageCollectionSlice';
import walletsReducer from '../services/wallets/walletsSlice'
import eventsReducer from '../services/events/eventsSlice'
import reservationsReducer from '../services/reservations/reservationsSlice'
import popUp from '../components/popUp/popUpSlice';

import { apiSlice } from '../services/apiSlice/apiSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    imagesCollection: imagesCollectionReducer,
    wallets: walletsReducer,
    events: eventsReducer,
    reservations: reservationsReducer,
    popUp,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActionPaths: ['payload.bannerImage', 'payload.logoImage', 'payload.additionalImages', 'payload.tokenImages'],
      //   ignoredPaths: ['imagesCollection'],
      // },
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

setupListeners(store.dispatch)

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store