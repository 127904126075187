import React, { useState } from "react";
import "./collectionCard.css"
import BlackEdit from "../../assets/icons/penBlack.png"
import { NavLink } from "react-router-dom";

interface TColectionCard {
    cardInfo: {
        id: string,
        name: string,
        price: string,
        status: string,
        image: string
    }
}
export default function CollectionCard(props: TColectionCard) {
    const { id, name, price, status, image } = props.cardInfo

    const editHandler = () => {

    }
    return (
        <div className="collectionCard">
            <div className="collectionCard-Image">
                <img src={image} alt={name} className="cardImage" />
                <NavLink to={`/collections/${id}-item`} className="cardEdit" onClick={editHandler}>
                    <img src={BlackEdit} alt="editButton" className="cardEdit-image" />
                </NavLink>

            </div>
            <div className="collectionCard-Info">
                <p>{name}</p>
                <p>Price: {price}</p>
                <p>Status: {status}</p>
            </div>
        </div>
    );
}